import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Button,
  CustomInput,
  Table
} from "reactstrap";

import firebase from "firebase.config";
import Page4 from "./Page4";
import Page5 from "./Page5";

export default class GroupDetails extends React.Component {
  state = {
    name: "",
    image: "",
    uploading: false,

    page4: [
      {
        title: "",
        url: ""
      }
    ],

    page5Title: "",
    page5: [
      {
        image: null,
        title: "",
        buttons: [
          {
            image: null,
            title: "",
            htmlData: ""
          }
        ]
      }
    ]
  };

  componentDidMount = () => {
    this.initialize();
  };

  initialize = () => {
    if (this.props.groupData.pageNames) {
      // Page 4 Title
      var temp = this.state.page4;
      temp[0].title = this.props.groupData.pageNames.page4;
      this.setState({ page4: temp });

      // Page 5 Title
      this.setState({
        page5Title: this.props.groupData.pageNames.page5
      });
    }

    // Page 4 URL
    if (this.props.groupData) {
      var temp = this.state.page4;

      // temp[0].url = this.props.groupData.url;
      temp[0].url = this.props.groupData.youtubeUrl;

      this.setState({
        page4: temp
      });
    }

    console.log("Group Details Global Page 4", this.props.groupData);
    console.log("Group Details Local Page 4", this.state.page4);

    console.log("Group Details Global Page 5", this.props.groupData);
    console.log("Group Details Local Page 5", this.state.page5);

    // Page 5 Data
    if (this.props.groupData.page5) {
      var temp = this.state.page5;

      temp[0].image = this.props.groupData.page5.image;
      temp[0].title = this.props.groupData.page5.title;
      temp[0].buttons = this.props.groupData.page5.buttons;

      this.setState({
        page5: temp
      });
    }
  };

  handleChange = (event, key) => {
    this.setState({
      [key]: event.target.value
    });
  };

  // *************************
  //   PUSH DATA TO DATABASE
  // *************************

  pushPageNamesToDB = async () => {
    await firebase
      .database()
      .ref("groupsData/" + this.props.userDetails.groupPath + "/pageNames/")
      .update({
        page4: this.state.page4[0].title,
        page5: this.state.page5Title
      });
  };

  pushPage4DataToDB = async () => {
    await firebase
      .database()
      .ref("groupsData/" + this.props.userDetails.groupPath)
      .update({
        youtubeUrl: this.state.page4[0].url
      });
  };

  pushPage5DataToDB = async () => {
    await firebase
      .database()
      .ref("groupsData/" + this.props.userDetails.groupPath)
      .update({
        page5: this.state.page5[0]
      });
  };

  updateDetails = () => {
    let { name } = this.state;
    if (name.length === 0) {
      name = this.props.groupData.name;
    }
    if (name.length < 2) {
      alert("Please enter valid group name");
      return;
    }

    firebase
      .database()
      .ref("/groupsData/" + this.props.userDetails.groupPath)
      .update({
        name,
        image: this.state.image ? this.state.image : this.props.groupData.image
      })
      .then(res => {
        this.setState({
          name: ""
        });
        alert("Updated successfully");
      });

    this.pushPage4DataToDB();
    this.pushPage5DataToDB();
    this.pushPageNamesToDB();
  };

  saveImage = async event => {
    this.setState({
      uploading: true
    });
    let file = event.target.files[0];

    if (file) {
      var filename = this.props.userDetails.group + "-logo";

      const storage = firebase.storage().ref();
      const image = storage.child(filename);

      await image.put(file).then(async snapshot => {
        await image.getDownloadURL().then(async url => {
          this.setState({
            image: url,
            uploading: false
          });
        });
      });
      console.log("Image Uploaded!!!");
    }
  };

  setPageNames = (event, index) => {
    var temp = this.state.pageNames;

    temp[index] = event.target.value;

    this.setState({
      pageNames: temp
    });
  };

  // ****************************
  //   PAGE 4 ONCHANGE FUNCTION
  // ****************************

  handleURLChange = value => {
    let temp = this.state.page4;

    temp[0].url = value;

    this.setState({
      page4: temp
    });
    console.log("URL:", this.state.page4);
  };

  handlePage4TitleChange = value => {
    let temp = this.state.page4;

    temp[0].title = value;

    this.setState({
      page4: temp
    });
    console.log("Title:", this.state.page4);
  };

  // ****************************
  //   PAGE 5 ONCHANGE FUNCTION
  // ****************************

  handlePage5TitleChange = value => {
    this.setState({
      page5Title: value
    });
  };

  addHeader = () => {
    var temp = this.state.page5;

    temp[0].buttons.push({
      image: null,
      title: "",
      htmlData: ""
    });

    this.setState({
      page5: temp
    });
  };

  handleTitleChange = (value, index) => {
    let temp = this.state.page5;
    console.warn(value)
    temp[0].buttons[index].title = value;

    this.setState({
      page5: temp
    });
  };

  handleEditorChange = (value, index) => {
    let items = this.state.page5;

    console.log("HTML Data: ", items[0].items[index]);
    items[0].buttons[index].htmlData = value;

    this.setState({
      page5: items
    });
  };

  handleEditorUpdate = (val, index) => {
    let items = this.state.page5;

    // items[0].buttons[index].htmlData = val;
    items[0].buttons[index].htmlData = val;

    this.setState({
      page5: items
    });
  };

  render() {
    return (
      <>
        <div className="content">
          {!this.props.userDetails.group ? (
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Error</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <p>
                        Dear {this.props.userDetails.name}, before you can
                        update group details please set Unique Identifier for
                        your group from the dashboard.
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ) : (
              <>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h5">Group Details</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <div
                              style={{
                                flexDirection: "row",
                                display: "flex"
                              }}
                            >
                              <Input
                                type="text"
                                placeholder="Enter Group ID"
                                value={this.props.userDetails.group}
                                style={{ margin: 5 }}
                                disabled={true}
                                onChange={event => {
                                  this.handleChange(event, "name");
                                }}
                              />
                              <Input
                                type="text"
                                placeholder="Enter Group Name"
                                defaultValue={
                                  this.props.groupData
                                    ? this.props.groupData.name
                                    : ""
                                }
                                style={{ margin: 5 }}
                                onChange={event => {
                                  this.handleChange(event, "name");
                                }}
                              />
                            </div>
                            <div
                              className="form-control"
                              style={{
                                margin: 5,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                              }}
                            >
                              <Input
                                type="file"
                                style={{
                                  //   fontSize: 18,
                                  //   visibility: "hidden",
                                  position: "relative",
                                  opacity: 1,
                                  flexDirection: "row",
                                  display: "flex"
                                }}
                                onChange={event => {
                                  this.saveImage(event);
                                }}
                              />
                              {this.props.groupData.image || this.state.image ? (
                                <img
                                  style={{
                                    height: 70,
                                    width: "auto",
                                    borderRadius: 10
                                  }}
                                  src={
                                    this.state.image
                                      ? this.state.image
                                      : this.props.groupData.image
                                  }
                                />
                              ) : null}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* <Button
                      className="btn-round"
                      style={{ marginBottom: 20 }}
                      color="primary"
                      type="submit"
                      onClick={() => {
                        this.updateDetails();
                      }}
                      disabled={this.state.uploading}
                    >
                      Update Group Details
                    </Button> */}
                    </CardBody>
                  </Card>
                </Col>

                {/* Page 4 */}
                <div>
                  <Page4
                    obj={this.state.page4}
                    onUrlChange={value => {
                      this.handleURLChange(value);
                    }}
                    onTitleChange={value => {
                      this.handlePage4TitleChange(value);
                    }}
                  />
                </div>

                {/* Page 5 */}
                <div>
                  <Page5
                    obj={this.state.page5}
                    key={this.state.page5}
                    title={this.state.page5Title}
                    onTitleChange={value => {
                      this.handlePage5TitleChange(value);
                    }}
                    onAddHeader={() => {
                      this.addHeader();
                    }}
                    onHandleTitleChange={(value, index) => {
                      this.handleTitleChange(value, index);
                    }}
                    onHandleEditorChange={(value, index) => {
                      this.handleEditorChange(value, index);
                    }}
                    onHandleEditorUpdate={(value, index) => {
                      this.handleEditorUpdate(value, index);
                    }}
                  />
                </div>

                <Button
                  className="btn-round"
                  style={{ marginBottom: 20, marginRight: 15, float: "right" }}
                  color="primary"
                  type="submit"
                  onClick={() => {
                    this.updateDetails();
                  }}
                  disabled={this.state.uploading}
                >
                  Update Group Details
              </Button>
              </>
            )}
        </div>
      </>
    );
  }
}
