import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Table
} from "reactstrap";
import firebase from "firebase.config";

export default class ExistingMagazines extends React.Component {
  state = {
    selectedNewsletter: null
  };

  rawMarkup(param) {
    return { __html: param };
  }

  editNewsLetter = (key, edit) => {
    this.props.editPage(key, edit);
  };

  deleteNewsLetter = key => {
    let res = window.confirm(
      "Are you sure that you want to delete this newsletter? you won't be able to recover it"
    );
    if (res) {
      console.log(key);
      firebase
        .database()
        .ref("groupsData/" + this.props.groupPath + "/newsletters/" + key)
        .remove();
    }
    console.log("groupsData/" + this.props.groupPath + "/newsletters/" + key);
  };

  // deleteNewsLetter = (newsletter) => {
  //   let res = window.confirm("Are you sure that you want to delete this newsletter? you won't be able to recover it")
  //   if (res) {
  //     console.warn(this.state.selectedKey)
  //     firebase.database().ref("")
  //   }
  // }
  render() {
    console.warn(this.props);
    let keys = this.props.newsletters
      ? Object.keys(this.props.newsletters)
      : [];
    return (
      <>
        <div className="content">
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Newsletter</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Card className="card-plain">
                        <CardBody>
                          <Table>
                            <thead className="text-primary">
                              <tr>
                                <th>Date</th>
                                <th>No Of Weeks</th>
                                <th className="text-right"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {keys.map((key, index) => {
                                return (
                                  <tr>
                                    <td>
                                      {/* {new Date(
                                        parseInt(key.split("+")[0])
                                      ).toDateString()} */}
                                      {new Date(
                                        Date.parse(key.split("+")[0])
                                      ).toUTCString().split("00:00")[0]}
                                    </td>
                                    <td>{key.split("+")[1]}</td>
                                    <td>
                                      <Button
                                        className="btn-round"
                                        style={{
                                          marginBottom: 20,
                                          marginLeft: 20
                                        }}
                                        color="primary"
                                        type="submit"
                                        onClick={() => {
                                          this.setState({
                                            selectedNewsletter: this.props
                                              .newsletters[key],
                                            selectedKey: key
                                          });
                                        }}
                                      >
                                        View Newsletter
                                      </Button>
                                    </td>
                                    <td
                                      style={{
                                        display: "flex",
                                        flexDirection: "column"
                                      }}
                                    >
                                      <Button
                                        className="btn-round"
                                        style={{
                                          marginBottom: 10
                                        }}
                                        color="primary"
                                        type="submit"
                                        onClick={() => {
                                          this.editNewsLetter(key, true);
                                        }}
                                      >
                                        Edit
                                      </Button>
                                      <Button
                                        className="btn-round"
                                        style={{
                                          marginBottom: 10
                                        }}
                                        color="danger"
                                        type="submit"
                                        onClick={() => {
                                          this.deleteNewsLetter(key);
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </td>
                                    <td>
                                      <Button
                                        className="btn-round"
                                        style={{
                                          marginBottom: 20
                                        }}
                                        color="primary"
                                        type="submit"
                                        onClick={() => {
                                          this.editNewsLetter(key);
                                        }}
                                      >
                                        Duplicate
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            {this.state.selectedNewsletter && (
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">
                    Newsletter for{" "}
                    {/* {new Date(
                      parseInt(this.state.selectedKey.split("+")[0])
                    ).toDateString()}{" "} */}
                    {new Date(
                      Date.parse(this.state.selectedKey.split("+")[0])
                    ).toUTCString().split("00:00")[0]}{" "}
                    Week {this.state.selectedKey.split("+")[1]}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Card className="card-plain">
                          <CardBody>
                            {/* <h4>Page 1</h4> */}
                            <h4>
                              {this.state.selectedNewsletter.pageNames.page1}
                            </h4>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                              }}
                            >
                              <img
                                src={
                                  this.state.selectedNewsletter.data.page1.image
                                }
                              />
                              <h5 style={{ padding: 10 }}>
                                {
                                  this.state.selectedNewsletter.data.page1
                                    .imageText
                                }
                              </h5>
                            </div>
                            {this.state.selectedNewsletter.data.page1.tableArray.map(
                              (table, index) => {
                                return (
                                  <Table style={{ marginTop: 15 }}>
                                    <tr>
                                      <th colspan={2}>{table.header}</th>
                                    </tr>
                                    {table.rows.map((row, index) => {
                                      return (
                                        <tr>
                                          <td style={{ flex: 3 }}>
                                            {row.column1}
                                          </td>
                                          <td style={{ flex: 7 }}>
                                            {row.column2}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </Table>
                                );
                              }
                            )}
                            {/* <h4>Page 2</h4> */}
                            <h4>
                              {this.state.selectedNewsletter.pageNames.page2}
                            </h4>
                            {this.state.selectedNewsletter.data.page2.map(
                              (table, index) => {
                                return (
                                  <Table style={{ marginTop: 15 }}>
                                    <tr>
                                      <th colspan={2}>{table.header}</th>
                                    </tr>

                                    <tr>
                                      <td style={{ flex: 1 }}>{table.text}</td>
                                    </tr>
                                    <tr>
                                      <td style={{ flex: 1 }}>{table.text2}</td>
                                    </tr>
                                  </Table>
                                );
                              }
                            )}
                            {/* <h4>Page 3</h4> */}
                            <h4>
                              {this.state.selectedNewsletter.pageNames.page3}
                            </h4>
                            <Table style={{ marginTop: 15 }}>
                              <tr>
                                <th colspan={2}>
                                  {
                                    this.state.selectedNewsletter.data.page3
                                      .header
                                  }
                                </th>
                              </tr>

                              <tr>
                                <td style={{ flex: 1 }}>
                                  {/* {
                                    this.state.selectedNewsletter.data.page3
                                      .text
                                  } */}
                                  {<div
                                    dangerouslySetInnerHTML={this.rawMarkup(
                                      this.state.selectedNewsletter.data.page3
                                        .text
                                    )}
                                  />}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ flex: 1 }}>
                                  {
                                    this.state.selectedNewsletter.data.page3
                                      .text2
                                  }
                                </td>
                              </tr>
                            </Table>

                            {/* COMMENTS START */}

                            {/* <h4>Page 4</h4>
                            <b>Youtube channel URL:</b>{" "}
                            <a href={this.state.selectedNewsletter.data.page4}>
                              {this.state.selectedNewsletter.data.page4}
                            </a>
                            <h4>Page 5</h4>
                            {this.state.selectedNewsletter.data.page5.buttons.map(
                              (subpage, index) => {
                                return (
                                  <div
                                    style={{
                                      flexDirection: "row",
                                      flex: 1,
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center"
                                    }}
                                  >
                                    <b>{subpage.title}</b>
                                    <div
                                      style={{
                                        flex: 1,
                                        margin: 10,
                                        borderWidth: 1,
                                        borderStyle: "solid",
                                        borderColor: "rgba(0,0,0,0.2)",
                                        borderRadius: 5,
                                        paddingLeft: 5,
                                        paddingRight: 5
                                      }}
                                    >
                                      <div
                                        dangerouslySetInnerHTML={this.rawMarkup(
                                          subpage.htmlData
                                        )}
                                      />
                                    </div> */}
                            {/* <p style={{ flex: 1 }}>{subpage.htmlData}</p> */}
                            {/* <img
                                      style={{ height: 100 }}
                                      src={subpage.image}
                                    />
                                  </div>
                                );
                              }
                            )} */}
                            {/* COMMENT END */}
                            <Button
                              className="btn-round"
                              style={{ marginBottom: 20, marginLeft: 20 }}
                              color="primary"
                              type="submit"
                              onClick={() => {
                                this.setState({
                                  selectedNewsletter: null,
                                  selectedKey: null
                                });
                              }}
                            >
                              Close
                            </Button>
                            {/* 
                            <Button
                              className="btn-round"
                              style={{ marginBottom: 20, }}
                              color="danger"
                              type="submit"
                              onClick={() => {
                                this.deleteNewsLetter()
                              }}
                            >
                              Delete Newsletter
                                      </Button> */}
                          </CardBody>
                        </Card>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          </Col>
        </div>
      </>
    );
  }
}
