import React, { Component } from 'react'
import firebase from './../firebase.config'
class Logout extends React.Component {
    constructor(props) {
        super(props)
        firebase.auth().signOut()
    }

    render() {
        return (
            <div>

            </div>
        )
    }
}
export default Logout;