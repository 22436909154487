import firebase from "firebase";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyC6hU4jgSN1j6y2im0W-xgTiLnJdFb9-eY",
  authDomain: "newsletterapp-b6070.firebaseapp.com",
  databaseURL: "https://newsletterapp-b6070.firebaseio.com",
  projectId: "newsletterapp-b6070",
  storageBucket: "newsletterapp-b6070.appspot.com",
  messagingSenderId: "420136308827",
  appId: "1:420136308827:web:8553929d49f0f5a7cc2f7e",
  measurementId: "G-GPMR956J3B"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
