import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import firebase from 'firebase.config'

export default class Recover extends Component {
    state = {
        email: ""
    }

    sendRecoveryEmail = () => {
        var auth = firebase.auth();

        auth.sendPasswordResetEmail(this.state.email).then(function () {
            alert("Recovery email sent successfully")
        }).catch(function (error) {
            alert(error.message)
        });
    }
    render() {
        return (
            <div style={{ width: '100%', paddingLeft: '7%', paddingRight: '7%', marginTop: 30 }}>
                <h3 style={styles.title}>Recover your password</h3>
                <p>An email will be sent to the above email address with a step by step guide on how to recover your account</p>
                <input style={styles.input}
                    placeholder="Email Address"
                    onChange={(event) => {
                        this.setState({
                            email: event.target.value
                        })
                    }}
                />



                <button style={styles.button} onClick={this.sendRecoveryEmail}>Send recovery email</button>
                <NavLink
                    to={'/auth/login'}
                    className="nav-link"
                    style={styles.buttonPass}
                >Go Back</NavLink>

            </div >
        )
    }
}


const styles = {
    title: {
        width: '100%',
        textAlign: 'center',
        fontWeight: '600'
    },
    input: {
        width: '100%',
        marginTop: 30,
        borderRadius: 5,
        borderColor: 'rgb(190,190,190)',
        borderWidth: 1,
        borderStyle: 'solid',
        padding: 20,
    },
    button: {
        marginTop: 20,
        width: '100%',
        padding: 12,
        backgroundColor: 'rgb(88,141,240)',
        border: 0,
        borderRadius: 5,
        color: 'white',
        fontWeight: '600'
    },
    buttonPass: {
        marginTop: 5,
        backgroundColor: 'transparent',
        borderWidth: 0,
        width: '100%',
        textAlign: 'center',
        cursor: 'pointer',
        cursor: 'hand',
        fontWeight: '600',
        color: 'black'
    },
    buttonSignup: {
        marginTop: 30,
        backgroundColor: 'transparent',
        borderWidth: 0,
        width: '100%',
        textAlign: 'center'
    }
}