import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  FormGroup,
  Input,
  Button,
  Label
} from "reactstrap";

import firebase from "firebase.config";
import DatePicker from "react-datepicker";
import axios from 'axios'

import "react-datepicker/dist/react-datepicker.css";
export default class CreateMagazine extends React.Component {
  state = {
    enable: true,
    isEmpty: true,
    pageNames: ["", "", ""],

    magazine: [
      {
        date: "",
        noOfWeeks: 1,
        image: null,
        text: "",

        headers: [
          {
            header: "",
            rows: [
              {
                column1: "",
                column2: ""
              }
            ]
          }
        ]
      }
    ]
  };

  checkIfEmpty = async page => {
    var temp = this.state.magazine[0];


    // let oldDates = Object.keys(this.props.newsletters).map(d => d.split("+")[0])
    // console.warn("Old Dates", oldDates)

    // if (!temp.date || !temp.noOfWeeks || !temp.image || !temp.headers) {
    console.warn("BITRUPT", temp.date, temp.noOfWeeks, temp.headers)
    // if (!temp.date || !temp.noOfWeeks || !temp.headers) {
    if (!temp.date || !temp.headers) {
      console.warn(!temp.date, !temp.noOfWeeks, !temp.headers)
      this.alertTheUser();
      return;
    }
    if (this.props.newsletters)
      if (this.props.newsletters[temp.date + "+" + temp.noOfWeeks] && !this.props.edit) {
        alert("You have already posted a newsletter on selected date");
        return
      }
    if (temp.headers) {
      await temp.headers.map(tableArray => {
        // console.log("Reached First Map Function");
        if (!tableArray.header || !tableArray.rows) {
          this.alertTheUser();
          // console.log("Returned Header/Row");
          return;
        } else if (tableArray.rows) {
          tableArray.rows.map(row => {
            // console.log("Reached Second Map Function");
            if (!row.column1 || !row.column2) {
              this.alertTheUser();
              // console.log("Returned Column1/2");
              return;
            }
          });
        }
      });
    }
    this.routeToNextPage(page);
  };

  routeToNextPage = page => {
    this.props.changePage(this.state.magazine, page);
  };

  alertTheUser = () => {
    alert("All fields are required");
    return;
  };

  componentDidMount = () => {
    this.initialize();
    console.warn("EXISTING NEWSLETTERS", this.props.newsletters)
  };

  initialize = () => {
    console.log("Page 1:");
    console.log("Global Props: ", this.props);

    var temp = this.state.magazine;
    // temp[0].date = new Date().getTime();
    // temp[0].noOfWeeks = new Date().getWeekOfMonth();
    console.log("For Date and No. of Weeks:", this.props.obj);
    temp[0].date = this.props.obj[0].date;
    temp[0].noOfWeeks = this.props.obj[0].noOfWeeks;
    temp[0].image = this.props.obj[0].magazine1.page1.image;
    temp[0].text = this.props.obj[0].magazine1.page1.imageText;
    temp[0].headers = this.props.obj[0].magazine1.page1.tableArray;

    this.setState({
      magazine: temp,
      pageNames: this.props.pageNames
    });

    console.log("Local Obj: ", this.state.magazine);
  };

  addImage = async event => {
    this.setState({
      enable: false
    });

    var temp = this.state.magazine;
    temp[0].imageTemp = event.target.files[0];

    this.setState({
      data: temp
    });

    console.log("Image To Be Uploaded!");

    await this.savePage1Image();
    this.setState({
      enable: true
    });
  };

  savePage1Image = async () => {
    let temp = this.state.magazine;
    let file = temp[0].imageTemp;

    if (file) {
      var min = 1;
      var max = 100;
      var rand = min + Math.random() * (max - min);

      var filename =
        this.props.obj[0].date +
        "+" +
        this.props.obj[0].noOfWeeks +
        "+" +
        "page1" +
        "+" +
        rand;

      const storage = firebase.storage().ref();
      const image = storage.child(filename);

      await image.put(file).then(async snapshot => {
        await image.getDownloadURL().then(async url => {
          console.log("Image URL:", url);
          temp[0].image = url;
          delete temp[0].imageTemp;
          this.setState({
            magazine: temp
          });
        });
      });
      console.log("Image Uploaded!!!");
    }
  };

  addHeader = () => {
    var temp = this.state.magazine;

    temp[0].headers.push({
      header: "",
      rows: [{ column1: "", column2: "" }]
    });

    this.setState({
      magazine: temp
    });
  };

  addRow = index => {
    let temp = this.state.magazine;

    temp[0].headers[index].rows.push({
      column1: "",
      column2: ""
    });

    this.setState({
      magazine: temp
    });
  };

  removeRow = (index, indexInner) => {
    let temp = this.state.magazine;

    temp[0].headers[index].rows.splice(indexInner, 1);

    this.setState({
      magazine: temp
    });
  };

  handleDatechange = async event => {
    let temp = this.state.magazine;
    console.warn("SELECTED DATE", event.target.value.toString())
    temp[0].date = event.target.value.toString();

    // axios.get("https://us-central1-bitrupt-bot.cloudfunctions.net/getWeekNumber?date=" + temp[0].date).then(res => {
    //   console.warn("response is", res.data)
    //   temp[0].noOfWeeks = res.data;

    // })
    //   .catch(err => {
    //     temp[0].noOfWeeks = this.handleWeekchange(temp[0].date);

    //   })
    temp[0].noOfWeeks = this.handleWeekchange(temp[0].date);


    // for week number
    const proxyurl = "https://cors-anywhere.herokuapp.com/";
    const url = "https://us-central1-bitrupt-bot.cloudfunctions.net/getWeekNumber?date=" + temp[0].date; // site that doesn’t send Access-Control-*
    await fetch(proxyurl + url)
      .then(response => response.text())
      .then(contents => {
        console.warn(JSON.parse(contents).weekNum)
        temp[0].noOfWeeks = JSON.parse(contents).weekNum;
        console.warn("Fetched week is", temp[0].noOfWeeks)
      })
      .catch(() => {
        temp[0].noOfWeeks = this.handleWeekchange(temp[0].date);
      })


    // console.warn("Weeek is", temp[0].noOfWeeks)
    // console.warn(event.target.value.toString())
    // console.warn(new Date(event.target.value.toString()).toDateString())
    this.setState({
      magazine: temp
    });
    console.log("Date:", this.state.magazine);
  };


  handleDatechange2 = event => {
    let temp = this.state.magazine;
    let date = new Date(event)
    let dateString = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    let dateString2 = date.getFullYear() + "-0" + (date.getMonth() + 1) + "-0" + date.getDate()
    console.warn(dateString2)
    temp[0].date = dateString2
    temp[0].noOfWeeks = 0

    this.setState({
      magazine: temp
    });
    console.log("Date:", this.state.magazine);
  };

  handleWeekchange = date => {
    console.warn("DATEEE", date)
    // console.warn("week change", date)
    var temp = date.split("-")[0] + "-" + date.split("-")[1] + "-" + "1";
    console.warn(temp)
    var firstWeekday = new Date(temp).getDay();
    console.warn(firstWeekday)

    // take 1st day of week, take the date out from string
    // subtract 1 because it's already counted in 1st day
    // divide by 7 (no of days in a week)
    // add 1 because current form represents '1' as '0'
    // overall parseInt to convert into integer if float
    var weekNo =
      parseInt((firstWeekday + (parseInt(date.split("-")[2]) - 1)) / 7) + 1;
    console.warn(weekNo)


    // console.warn("WEEK NO", weekNo)

    let dateStored = new Date(date)
    let day = new Date(date).getDay()

    let firstOfMonth = new Date(date)
    firstOfMonth.setDate(1)

    if (firstOfMonth.getDay() === 0) {
      console.warn("Case 1")

      // console.warn("Simple case")
      return weekNo;

    }
    else if (dateStored.getDate() - day <= 0 && day !== 0) {
      console.warn("Condition fulfilled")
      //get last week of previous month
      let prevMonthSunday = new Date(dateStored);
      prevMonthSunday.setDate(prevMonthSunday.getDate() - day)
      let weekNum = this.handleWeekChangeFromDate(prevMonthSunday)

      // console.warn("DATE is", prevMonthSunday.toISOString(), "Weeek Num", weekNum)
      return weekNum;
    }
    else {
      console.warn("Case 3")

      return weekNo - 1
    }
    // console.warn("DAY No", day)



    // return weekNo;
  };

  handleWeekChangeFromDate = date => {
    var firstWeekday = date.getDay();

    // take 1st day of week, take the date out from string
    // subtract 1 because it's already counted in 1st day
    // divide by 7 (no of days in a week)
    // add 1 because current form represents '1' as '0'
    // overall parseInt to convert into integer if float
    var weekNo =
      parseInt((firstWeekday + (parseInt(date.getDate()) - 1)) / 7) + 1;

    return weekNo;

  }



  weekCount = (year, month_number) => {

    // month_number is in the range 1..12

    var firstOfMonth = new Date(year, month_number - 1, 1);
    var lastOfMonth = new Date(year, month_number, 0);

    var used = firstOfMonth.getDay() + lastOfMonth.getDate();

    return Math.ceil(used / 7);
  }


  getWeekOfMonth = (date) => {

    // let day = date.getDate()
    // day -= (date.getDay() == 0 ? 6 : date.getDay() - 1);//get monday of this week
    // //special case handling for 0 (sunday)

    // day += 7;
    // //for the first non full week the value was negative

    // let prefixes = ['0', '1', '2', '3', '4', '5'];
    // return prefixes[0 | (day) / 7];
    //sunday is 0

    let day = date.getDay()
    console.warn(day)

    return 0;
  }

  // handleWeekchange = event => {
  //   let temp = this.state.magazine;

  //   temp[0].noOfWeeks = event.target.value.toString();

  //   this.setState({
  //     magazine: temp
  //   });
  // };

  handleTextChange = event => {
    let temp = this.state.magazine;

    temp[0].text = event.target.value;

    this.setState({
      magazine: temp
    });
  };

  handleHeadingChange = (event, index) => {
    let temp = this.state.magazine;

    temp[0].headers[index].header = event.target.value;

    this.setState({
      magazine: temp
    });
  };

  handleColumnChange = (event, index, indexInner, col1) => {
    let temp = this.state.magazine;

    col1
      ? (temp[0].headers[index].rows[indexInner].column1 = event.target.value)
      : (temp[0].headers[index].rows[indexInner].column2 = event.target.value);

    this.setState({
      magazine: temp
    });
  };

  setPageNames = (event, index) => {
    var temp = this.state.pageNames;

    temp[index] = event.target.value;
    console.log("Page " + index + ":", event.target.value);
    console.log("Temp:", temp);

    this.setState(
      {
        pageNames: temp
      },
      () => {
        this.props.setPageTitles(this.state.pageNames);
      }
    );
  };

  getDate = () => {
    if (this.state.magazine[0].date.length > 0) {
      console.warn("Case 1")
      return new Date(this.state.magazine[0].date)
    }
    else if (this.props.obj[0].date) {
      let d = this.props.obj[0].date
      console.warn("Case 2")

      if (d.split("-").length === 3) {
        console.warn("Case 2-1")
        return new Date()
      }
      else {
        console.warn("Case 2-2")

        return new Date(d)
      }
    }
    else {
      console.warn("Case 3")

      return new Date()
    }
    // console.warn("Get date called")
    // if (d.split("-").length === 3) {
    //   console.warn("Length 333")
    //   return new Date()
    // }
    // else {
    //   return new Date(d)
    // }

  }

  render() {
    // Date.prototype.getWeek = function (dowOffset) {
    //   /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */
    //   let nYear, nday;
    //   dowOffset = typeof (dowOffset) == 'int' ? dowOffset : 0; //default dowOffset to zero
    //   var newYear = new Date(this.getFullYear(), 0, 1);
    //   var day = newYear.getDay() - dowOffset; //the day of week the year begins on
    //   day = (day >= 0 ? day : day + 7);
    //   var daynum = Math.floor((this.getTime() - newYear.getTime() -
    //     (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) / 86400000) + 1;
    //   var weeknum;
    //   //if the year starts before the middle of a week
    //   if (day < 4) {
    //     weeknum = Math.floor((daynum + day - 1) / 7) + 1;
    //     if (weeknum > 52) {
    //       nYear = new Date(this.getFullYear() + 1, 0, 1);
    //       nday = nYear.getDay() - dowOffset;
    //       nday = nday >= 0 ? nday : nday + 7;
    //       /*if the next year starts before the middle of
    //         the week, it is week #1 of that year*/
    //       weeknum = nday < 4 ? 1 : 53;
    //     }
    //   }
    //   else {
    //     weeknum = Math.floor((daynum + day - 1) / 7);
    //   }
    //   return weeknum;
    // };

    // Date.prototype.getWeekOfMonth = function(exact) {
    //   var month = this.getMonth(),
    //     year = this.getFullYear(),
    //     firstWeekday = new Date(year, month, 1).getDay(),
    //     lastDateOfMonth = new Date(year, month + 1, 0).getDate(),
    //     offsetDate = this.getDate() + firstWeekday - 1,
    //     index = 1, // start index at 0 or 1, your choice
    //     weeksInMonth =
    //       index + Math.ceil((lastDateOfMonth + firstWeekday - 7) / 7),
    //     week = index + Math.floor(offsetDate / 7);
    //   if (exact || week < 2 + index) return week;
    //   return week === weeksInMonth ? index + 5 : week;
    // };
    console.warn(this.props.obj[0].date)
    return (
      <>
        {/* {this.initialize()} */}
        <div className="content">
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Set Page Titles</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <FormGroup
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <Input
                        type="text"
                        placeholder="Enter Page 1 Title"
                        defaultValue={this.state.pageNames[0]}
                        style={{ margin: 5 }}
                        onChange={event => {
                          this.setPageNames(event, 0);
                        }}
                      />
                      <Input
                        type="text"
                        placeholder="Enter Page 2 Title"
                        defaultValue={this.state.pageNames[1]}
                        style={{ margin: 5 }}
                        onChange={event => {
                          this.setPageNames(event, 1);
                        }}
                      />
                      <Input
                        type="text"
                        placeholder="Enter Page 3 Title"
                        defaultValue={this.state.pageNames[2]}
                        style={{ margin: 5 }}
                        onChange={event => {
                          this.setPageNames(event, 2);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Create New Newsletter</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>New Newsletter Details: </label>
                      <div
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          marginBottom: 10
                        }}
                      // className="form-control"
                      >
                        {/* Newsletter for {new Date().toDateString()}  Week  {this.state.magazine[0].noOfWeeks} */}
                        <div
                          style={{
                            flex: 1,
                            flexDirection: "column",
                            margin: 5
                          }}
                        >
                          <Label>Enter Date:</Label>
                          {/* <DatePicker
                            key={this.props.obj[0].date}
                            selected={this.getDate()}
                            dateFormat='dd/MM/yyyy'
                            showWeekNumbers
                            onChange={(event) => {
                              console.warn(event)
                              this.handleDatechange2(event);

                            }}
                            style={{ padding: 10, borderRadus: 10, marginLeft: 10 }}
                            customInput={<Input
                              placeholder="Enter Date"
                              defaultValue={this.props.obj[0].date}
                              style={{ marginLeft: 15 }}
                              required
                              // onChange={event => {
                              //   this.handleDatechange(event);
                              //   // console.log("Week:", this.handleWeekchange(temp[0].date));
                              // }}
                              disabled={true}
                            />}

                            disabled={this.props.edit ? true : false}
                          /> */}
                          <Input
                            placeholder="Enter Date"
                            defaultValue={this.props.obj[0].date}
                            type="date"
                            // style={{ margin: 5 }}
                            required
                            onChange={event => {
                              this.handleDatechange(event);
                              // console.log("Week:", this.handleWeekchange(temp[0].date));
                            }}
                            disabled={this.props.edit ? true : false}
                          />
                        </div>
                        {/* <div
                          style={{
                            flex: 1,
                            flexDirection: "column",
                            margin: 5
                          }}
                        >
                          <Label>Enter No. of Weeks:</Label>
                          <Input
                            placeholder="No of Weeks"
                            defaultValue={this.props.obj[0].noOfWeeks}
                            type="number"
                            // style={{ margin: 5 }}
                            required
                            onChange={event => {
                              this.handleWeekchange(event);
                            }}
                            disabled={this.props.edit ? true : false}
                          />
                        </div> */}
                      </div>
                      <div
                        className="form-control"
                        style={{
                          flexDirection: "row",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center"
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            flex: 1,
                            alignItems: "center"
                          }}
                        >
                          <p style={{ paddingRight: 10 }}>Main Image</p>
                          <input
                            type="file"
                            style={{
                              position: "relative",
                              visibility: "hidden",
                              opacity: 1,
                              flex: 1
                            }}
                            ref={ref => (this.myInput = ref)}
                            onChange={event => {
                              this.addImage(event);
                            }}
                          />
                          <Button
                            className="btn-round"
                            style={{ float: "right" }}
                            color="primary"
                            type="submit"
                            onClick={() => {
                              this.myInput.click();
                            }}
                          >
                            Browse
                          </Button>
                        </div>

                        {this.state.magazine[0].image ? (
                          <img
                            src={this.state.magazine[0].image}
                            style={{
                              margin: 5,
                              width: 75,
                              height: 75,
                              float: "right"
                            }}
                          />
                        ) : null}
                      </div>

                      <Input
                        type="textarea"
                        defaultValue={
                          this.props.obj[0].magazine1.page1.imageText
                        }
                        placeholder="Title Text"
                        style={{ margin: 5 }}
                        onChange={event => {
                          this.handleTextChange(event);
                        }}
                      />
                      <Button
                        className="btn-round"
                        style={{ float: "right" }}
                        color="primary"
                        type="submit"
                        onClick={() => {
                          this.addHeader();
                        }}
                      >
                        Add New Portion
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          {this.props.obj[0].magazine1.page1.tableArray.map((table, index) => {
            return (
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h5">New Portion</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>New Portion Details: </label>
                          <Input
                            type="text"
                            placeholder="Enter Header Title"
                            defaultValue={table.header}
                            style={{ margin: 5 }}
                            onChange={event => {
                              this.handleHeadingChange(event, index);
                            }}
                          />
                          {table.rows.map((row, indexInner) => {
                            return (
                              <div
                                style={{
                                  flexDirection: "row",
                                  display: "flex"
                                }}
                              >
                                <Input
                                  type="textarea"
                                  placeholder="Enter Column 1 Content"
                                  value={row.column1}
                                  style={{ margin: 5 }}
                                  required
                                  onChange={event => {
                                    this.handleColumnChange(
                                      event,
                                      index,
                                      indexInner,
                                      true
                                    );
                                  }}
                                />
                                <Input
                                  type="textarea"
                                  placeholder="Enter Column 2 Content"
                                  value={row.column2}
                                  style={{ margin: 5 }}
                                  required
                                  onChange={event => {
                                    this.handleColumnChange(
                                      event,
                                      index,
                                      indexInner,
                                      false
                                    );
                                  }}
                                />
                                <Button
                                  className="btn-round"
                                  style={{
                                    marginTop: 25,
                                    marginBottom: 25
                                  }}
                                  color="danger"
                                  type="submit"
                                  onClick={() => {
                                    this.removeRow(index, indexInner);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            );
                          })}
                          <Button
                            className="btn-round"
                            style={{
                              paddingTop: 7,
                              paddingBottom: 0,
                              paddingLeft: 15,
                              paddingRight: 15
                            }}
                            color="primary"
                            type="submit"
                            onClick={() => {
                              this.addRow(index);
                            }}
                          >
                            <p
                              style={{
                                fontSize: 30,
                                marginTop: 9,
                                fontWeight: "bold"
                              }}
                            >
                              +
                            </p>
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
          <Button
            className="btn-round"
            style={{ marginLeft: 20 }}
            color="primary"
            type="submit"
            onClick={() => {
              // this.checkIfEmpty("previous");
              this.routeToNextPage("previous");
              // this.props.changePage(this.state.magazine, "previous");
            }}
            disabled={this.state.enable ? false : true}
          >
            Back To List
          </Button>

          <Button
            className="btn-round"
            style={{ float: "right", marginRight: 15 }}
            color="primary"
            type="submit"
            onClick={() => {
              this.checkIfEmpty("next");
              // this.props.changePage(this.state.magazine, "next");
            }}
            disabled={this.state.enable ? false : true}
          >
            Next
          </Button>
        </div>
      </>
    );
  }
}
