import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button
} from "reactstrap";
import firebase from "firebase.config";

import ExistingMagazines from "./ExistingMagazines";
import CreateMagazine from "./CreateMagazine";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";

export default class Magazines extends React.Component {
  state = {
    edit: false,
    pageNames: ["", "", ""],
    page: 0,
    magazineObj: [
      {
        // name of magazine
        date: "",
        noOfWeeks: 0,

        // magazines details
        magazine1: {
          page1: {
            image: null,
            imageText: "",
            tableArray: [
              {
                header: "",
                rows: [
                  {
                    column1: "",
                    column2: ""
                  }
                ]
              }
            ]
          },
          page2: [
            {
              text: "",
              // text2: "",
              header: ""
            }
            // {
            //   text: "",
            //   text2: "",
            //   header: ""
            // },
            // {
            //   text: "",
            //   text2: "",
            //   header: ""
            // }
          ],
          page3: {
            header: "",
            text: ""
            // text2: ""
          }
          // page4: "",
          // page5: {
          //   image: null,
          //   title: "",
          //   buttons: [
          //     {
          //       htmlData: "",
          //       title: "",
          //       image: null
          //     }
          //     // {
          //     //   htmlData: "<p>asdasd</p>\n",
          //     //   title: "asd"
          //     // }
          //   ]
          // }
        }
      }
    ]
  };

  // *******************************************
  // ********** SAVE DATA TO DATABASE **********
  // *******************************************

  savePage1Images = async () => {
    let temp = this.state.magazineObj;
    let file = temp[0].magazine1.page1.image;
    if (file) {
      var min = 1;
      var max = 100;
      var rand = min + Math.random() * (max - min);

      var filename =
        this.state.magazineObj[0].date +
        "+" +
        this.state.magazineObj[0].noOfWeeks +
        "+" +
        "page1" +
        "+" +
        rand;

      const storage = firebase.storage().ref();
      const image = storage.child(filename);

      await image.put(file).then(async snapshot => {
        await image.getDownloadURL().then(async url => {
          console.log(url);
          temp[0].magazine1.page1.image = url;
          this.setState({
            magazineObj: temp
          });
        });
      });
    }
  };

  pushToDB = async () => {
    // console.log("Before");
    // console.log(this.state.magazineObj);
    // await this.saveImagesToDB();
    await firebase
      .database()
      .ref(
        "groupsData/" +
        this.props.userDetails.groupPath +
        "/newsletters/" +
        this.state.magazineObj[0].date +
        "+" +
        this.state.magazineObj[0].noOfWeeks
      )
      .update({
        data: this.state.magazineObj[0].magazine1
      });

    this.pushPageNamesToDB();

    console.log("After");
    console.log(this.state.magazineObj);
  };

  pushPageNamesToDB = async () => {
    await firebase
      .database()
      .ref(
        "groupsData/" +
        this.props.userDetails.groupPath +
        "/newsletters/" +
        this.state.magazineObj[0].date +
        "+" +
        this.state.magazineObj[0].noOfWeeks +
        "/pageNames/"
      )
      .update({
        page1: this.state.pageNames[0],
        page2: this.state.pageNames[1],
        page3: this.state.pageNames[2]
      });

    console.log("After");
    console.log(this.state.magazineObj);
  };

  // ***********************************************
  // ********** SAVE DATA TO STATE OBJECT **********
  // ***********************************************

  changePage = (data, changeTo) => {
    let temp = this.state.page;

    if (changeTo === "next") {
      temp += 1;
    } else {
      temp -= 1;
    }

    this.setState({
      page: temp
    });

    if (changeTo === "next") {
      temp -= 1;
    } else {
      temp += 1;
    }

    if (temp === 1) {
      this.setPage1Data(data);
    } else if (temp === 2) {
      this.setPage2Data(data);
    } else if (temp === 3) {
      this.setPage3Data(data);
      // } else if (temp === 4) {
      //   this.setPage4Data(data);
      // } else if (temp === 5) {
      //   this.setPage5Data(data);
    }
  };

  setPage1Data = data => {
    // set date and noOfWeeks of magazine
    let temp = this.state.magazineObj;
    temp[0].date = data[0].date;
    temp[0].noOfWeeks = data[0].noOfWeeks;

    // set page1 details of magazine
    let page1 = temp[0].magazine1.page1;
    page1.image = data[0].image;
    page1.imageText = data[0].text;

    page1.tableArray = data[0].headers;

    temp[0].magazine1.page1 = page1;

    this.setState({
      magazineObj: temp
    });
  };

  setPage2Data = data => {
    let temp = this.state.magazineObj;

    temp[0].magazine1.page2 = data;

    this.setState({
      magazineObj: temp
    });
  };

  setPage3Data = data => {
    let temp = this.state.magazineObj;

    temp[0].magazine1.page3 = data[0];

    this.setState({
      magazineObj: temp
    });
  };

  setPageNames = data => {
    let temp = this.state.pageNames;

    temp = data;

    this.setState({
      pageNames: temp
    });
  };

  // setPage4Data = data => {
  //   let temp = this.state.magazineObj;

  //   temp[0].magazine1.page4 = data;

  //   this.setState({
  //     magazineObj: temp
  //   });
  // };

  // setPage5Data = data => {
  //   console.log("Data: ", data);
  //   console.log("Obj:", this.state.magazineObj);
  //   let temp = this.state.magazineObj;

  //   temp[0].magazine1.page5.image = data[0].image;
  //   temp[0].magazine1.page5.buttons = data[0].buttons;

  //   this.setState(
  //     {
  //       magazineObj: temp
  //     },
  //     () => {
  //       console.warn(this.state.magazineObj);
  //     }
  //   );
  // };

  editPage = (key, edit) => {
    console.log("Magazines Page");
    console.log("Global Props:", this.props);
    console.log("Key:", key);

    var newsletter = this.props.groupData.newsletters[key].data;
    console.log(newsletter);
    console.log(this.state.magazineObj);

    // ***** SET PAGE 1 DATA *****
    let temp = this.state.magazineObj;
    temp[0].date = key.split("+")[0];
    temp[0].noOfWeeks = key.split("+")[1];

    let page1 = temp[0].magazine1.page1;
    page1.image = newsletter.page1.image;
    page1.imageText = newsletter.page1.imageText;

    page1.tableArray = newsletter.page1.tableArray;

    temp[0].magazine1.page1 = page1;

    // ***** SET PAGE 2 DATA *****
    temp[0].magazine1.page2 = newsletter.page2;

    // ***** SET PAGE 3 DATA *****
    temp[0].magazine1.page3 = newsletter.page3;

    this.setState({
      magazineObj: temp
    });

    // ***** SET PAGE NAMES *****
    var pageTitles = this.props.groupData.newsletters[key].pageNames;
    console.log(pageTitles);
    temp = this.state.pageNames;
    temp[0] = pageTitles.page1;
    temp[1] = pageTitles.page2;
    temp[2] = pageTitles.page3;

    this.setState({
      pageNames: temp,
      page: 1
    });

    if (edit) {
      this.setState({ edit: true });
    }

    // this.setState({ page: 1 });
    this.renderTargetPage();
  };

  renderTargetPage = () => {
    console.warn(this.props.groupData);
    if (this.state.page === 0) {
      return (
        <ExistingMagazines
          obj={this.state.magazineObj}
          groupPath={this.props.userDetails.groupPath}
          newsletters={
            this.props.groupData ? this.props.groupData.newsletters : []
          }
          setData={data => {
            this.setPage1Data(data);
          }}
          editPage={(key, edit) => {
            this.editPage(key, edit);
          }}
        />
      );
    } else if (this.state.page === 1) {
      return (
        <CreateMagazine
          obj={this.state.magazineObj}
          pageNames={this.state.pageNames}
          edit={this.state.edit}
          newsletters={
            this.props.groupData ? this.props.groupData.newsletters : []
          }
          changePage={(data, changeTo) => {
            this.changePage(data, changeTo);
          }}
          setPageTitles={data => {
            this.setPageNames(data);
          }}
        />
      );
    } else if (this.state.page === 2) {
      return (
        <Page2
          obj={this.state.magazineObj}
          changePage={(data, changeTo) => {
            this.changePage(data, changeTo);
          }}
        />
      );
    } else if (this.state.page === 3) {
      return (
        <Page3
          obj={this.state.magazineObj}
          edit={this.state.edit}
          changePage={(data, changeTo) => {
            this.changePage(data, changeTo);
          }}
          saveToDB={data => {
            this.setPage3Data(data);
            this.pushToDB();
          }}
        />
      );
    } else if (this.state.page === 4) {
      return (
        <Page4
          obj={this.state.magazineObj}
          changePage={(data, changeTo) => {
            this.changePage(data, changeTo);
          }}
        />
      );
    } else if (this.state.page === 5) {
      return (
        <Page5
          obj={this.state.magazineObj}
          changePage={(data, changeTo) => {
            this.changePage(data, changeTo);
          }}
          saveToDB={data => {
            this.setPage5Data(data);
            this.pushToDB();
          }}
        />
      );
    }
  };

  render() {
    return (
      <>
        <div className="content">
          {!this.props.userDetails.group ? (
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Error</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <p>
                        Dear {this.props.userDetails.name}, before you can add
                        new newsletter, please set Unique Identifier for your
                        group from the dashboard.
                      </p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ) : this.state.page === 0 ? (
            <>
              <Button
                className="btn-round"
                style={{ marginBottom: 20, marginLeft: 20 }}
                color="primary"
                type="submit"
                onClick={() => {
                  this.setState({ page: 1 });
                }}
              >
                Add New Newsletter
              </Button>
              {this.renderTargetPage()}
            </>
          ) : (
                <>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <h2
                      style={{
                        backgroundColor: "#51cbce",
                        color: "white",
                        borderRadius: 10,
                        paddingLeft: 18,
                        paddingRight: 18
                      }}
                    >
                      {this.state.page}
                    </h2>
                  </div>
                  {this.renderTargetPage()}
                </>
              )}
        </div>
      </>
    );
  }
}
