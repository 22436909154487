import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  FormGroup,
  Input,
  Button
} from "reactstrap";

import firebase from "firebase.config";
import WYSIWYGEditor from "components/WYSIWYGEditor";
export default class Page5 extends React.Component {
  state = {
    update: true,
    enable: true,
    title: "",

    header: [
      {
        image: null,
        title: "",
        buttons: [
          {
            image: null,
            title: "",
            htmlData: ""
          }
        ]
      }
    ]
  };

  constructor(props) {
    super(props);

    console.log("Page 5:");
    console.log("Local Obj: ", this.state.header);
    console.log("Global Props: ", this.props);
    // console.log("Key Props: ", this.props.key);

    let header = this.state.header;
    header[0] = this.props.obj[0];

    let temp = this.props.title;

    this.state = {
      header: header,
      title: temp,
      update: true,
      temp: "Hi"
    };
    // console.log("HtmlData:", this.state.header[0].buttons[0].htmlData);
    // console.log("Page 5 Local Obj:", this.state.header);
  }

  componentDidMount = () => {
    // this.initialize();
  };

  componentDidUpdate = () => {
    // console.log("componentDidUpdate()");

    if (this.state.update) {
      this.initialize();

      this.setState({
        update: false
      });
    }
  };

  initialize = () => {
    console.log("initialize()");
    let header = this.state.header;
    header[0] = this.props.obj[0];

    let temp = this.props.title;

    this.setState({
      header: header,
      title: temp
    });
  };

  addMainImage = async event => {
    // this.setState({
    //   enable: false
    // });

    var temp = this.state.header;
    temp[0].image = event.target.files[0];

    this.setState({
      data: temp
    });

    console.log("Image To Be Uploaded!");

    await this.saveMainImage();
    // this.setState({
    //   enable: true
    // });
  };

  saveMainImage = async () => {
    let temp = this.state.header;
    let file = temp[0].image;

    if (file) {
      var min = 1;
      var max = 100;
      var rand = min + Math.random() * (max - min);

      // var filename =
      //   // this.props.obj[0].date +
      //   this.state.date +
      //   "+" +
      //   // this.props.obj[0].noOfWeeks +
      //   this.state.noOfWeeks +
      //   "+" +
      //   "page5" +
      //   "+" +
      //   rand;
      var filename = "page5" + "+" + rand;

      const storage = firebase.storage().ref();
      const image = storage.child(filename);

      await image.put(file).then(async snapshot => {
        await image.getDownloadURL().then(async url => {
          console.log(url);
          temp[0].image = url;
          this.setState({
            magazineObj: temp
          });
        });
      });
    }
    console.log("Image Uploaded!!!");
  };

  addBgImage = async (event, index) => {
    // this.setState({
    //   enable: false
    // });

    var temp = this.state.header;
    temp[0].buttons[index].image = event.target.files[0];

    this.setState({
      data: temp
    });

    console.log("Image To Be Uploaded!");

    await this.saveBgImages(index);
    // this.setState({
    //   enable: true
    // });
  };

  saveBgImages = async index => {
    let temp = this.state.header;
    let file = temp[0].buttons[index].image;

    if (file) {
      var min = 1;
      var max = 100;
      var rand = min + Math.random() * (max - min);

      // var filename =
      //   // this.props.obj[0].date +
      //   // "+" +
      //   // this.props.obj[0].noOfWeeks +
      //   // "+" +
      //   "page5" +
      //   "+" +
      //   index +
      //   "+" +
      //   rand;
      var filename = "page5" + "+" + index + "+" + rand;

      const storage = firebase.storage().ref();
      const image = storage.child(filename);

      await image.put(file).then(async snapshot => {
        await image.getDownloadURL().then(async url => {
          console.log(url);
          temp[0].buttons[index].image = url;
          this.setState({
            header: temp
          });
        });
      });
    }
    console.log("Image Uploaded!!!");
  };

  addHeader = () => {
    // var temp = this.state.header;

    this.props.onAddHeader();
    // temp[0].buttons.push({
    //   image: null,
    //   title: "",
    //   htmlData: ""
    // });

    // this.setState(
    //   {
    //     header: temp
    //   },
    //   () => {
    //     this.props.onAddHeader();
    //   }
    // );
  };

  handleTitleChange = (event, index) => {
    this.props.onHandleTitleChange(
      event.target.value,
      index
    );
    // let temp = this.state.header;

    // temp[0].buttons[index].title = event.target.value;

    // this.setState(
    //   {
    //     header: temp
    //   },
    //   () => {
    //     this.props.onHandleTitleChange(
    //       this.state.header[0].buttons[index].title,
    //       index
    //     );
    //   }
    // );
  };

  handleEditorChange = (event, index) => {
    this.props.onHandleEditorChange(
      event.target.value,
      index
    );
    // let items = this.state.header;

    // // console.log("HTML Data: ", items[0].items[index]);
    // items[0].buttons[index].htmlData = event.target.value;

    // this.setState(
    //   {
    //     header: items
    //   },
    //   () => {
    //     this.props.onHandleEditorChange(
    //       this.state.header[0].buttons[index].htmlData,
    //       index
    //     );
    //   }
    // );
  };

  handleEditorUpdate = (val, index) => {
    this.props.onHandleEditorUpdate(
      val,
      index
    );
    // let items = this.state.header;

    // items[0].buttons[index].htmlData = val;

    // this.setState(
    //   {
    //     header: items
    //   },
    //   () => {
    //     this.props.onHandleEditorUpdate(
    //       this.state.header[0].buttons[index].htmlData,
    //       index
    //     );
    //   }
    // );
  };

  // myEditor = (value, index) => {
  //   return (
  //     <WYSIWYGEditor
  //       editorId="pageContent"
  //       onChange={event => {
  //         this.handleEditorChange(event, index);
  //       }}
  //       updateValues={val => {
  //         this.handleEditorUpdate(val, index);
  //       }}
  //       // defaultValue={table.htmlData}
  //       // defaultValue={table.title}
  //       defaultValue={value}
  //       // defaultValue={
  //       //   this.props.obj[0].buttons[index].htmlData
  //       // }
  //       description="Enter subpage content"
  //     />
  //   );
  // };

  myFunc = index => {
    return (
      <WYSIWYGEditor
        editorId="pageContent"
        onChange={event => {
          this.handleEditorChange(event, index);
        }}
        updateValues={val => {
          this.handleEditorUpdate(val, index);
        }}
        description="Enter subpage content"
      />
    );
  };


  deleteRow = (index) => {
    let data = this.state.header
    data[0].buttons.splice(index, 1)
    this.setState({
      header: data
    })
  }

  render() {
    return (
      <>
        <div className="content">
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Page 5 Content</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <Input
                        type="text"
                        placeholder="Enter Page 5 Title"
                        defaultValue={this.state.title}
                        style={{ margin: 5 }}
                        onChange={event => {
                          this.setState({ title: event.target.value }, () => {
                            this.props.onTitleChange(this.state.title);
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <div
                        style={{
                          margin: 5,
                          border: "1px solid lightgrey",
                          borderRadius: 5,
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          alignItems: "center"
                        }}
                      >
                        <label
                          style={{
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <p
                            style={{
                              backgroundColor: "#51cbce",
                              color: "white",
                              fontSize: 15,
                              padding: 5,
                              paddingLeft: 25,
                              paddingRight: 25,
                              display: "flex",
                              justifyContent: "center",
                              margin: 10,
                              borderRadius: 25
                            }}
                          >
                            {" "}
                            Browse
                          </p>
                          <Input
                            type="file"
                            style={{
                              fontSize: 18,
                              visibility: "hidden",
                              position: "relative",
                              opacity: 1,
                              margin: 5
                            }}
                            className="form-control"
                            onChange={event => {
                              this.addMainImage(event);
                            }}
                          />
                        </label>
                        {/* {this.props.obj[0].magazine1.page5.image && ( */}
                        {this.state.header[0].image && (
                          <img
                            // src={this.props.obj[0].magazine1.page5.image}
                            src={this.state.header[0].image}
                            style={{
                              margin: 5,
                              width: 75,
                              height: 75,
                              float: "right"
                            }}
                          />
                        )}
                      </div>
                      <Input
                        type="text"
                        placeholder="Enter Title"
                        // defaultValue={this.props.obj[0].magazine1.page5.title}
                        defaultValue={this.state.header[0].title}
                        style={{ margin: 5, flex: 1 }}
                        onChange={event => {
                          console.warn(this.state.magazine);

                          let header = this.state.header;
                          header[0].title = event.target.value;
                          this.setState(
                            {
                              magazine: header
                            },
                            () => {
                              console.warn(this.state.magazine);
                            }
                          );
                        }}
                      />
                      <Button
                        className="btn-round"
                        style={{ float: "right" }}
                        color="primary"
                        type="submit"
                        onClick={() => {
                          this.addHeader();
                        }}
                      // disabled={this.state.enable ? false : true}
                      >
                        Add Subpage
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          {/* {this.props.obj[0].magazine1.page5.buttons.map((table, index) => { */}
          {this.state.header[0].buttons.map((table, index) => {
            console.log("Table.htmlData:", table.htmlData);
            return (
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h5">New Sub-Page</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>New Sub page Details: </label>
                          <div
                            style={{
                              flexDirection: "row",
                              display: "flex"
                            }}
                          >
                            <div
                              style={{
                                margin: 5,
                                border: "1px solid lightgrey",
                                borderRadius: 5,
                                display: "flex",
                                flex: 1,
                                justifyContent: "space-between",
                                flexDirection: "row",
                                alignItems: "center"
                              }}
                            >
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                <p
                                  style={{
                                    backgroundColor: "#51cbce",
                                    color: "white",
                                    fontSize: 15,
                                    padding: 5,
                                    paddingLeft: 25,
                                    paddingRight: 25,
                                    display: "flex",
                                    justifyContent: "center",
                                    margin: 10,
                                    borderRadius: 25
                                  }}
                                >
                                  {" "}
                                  Browse
                                </p>
                                <Input
                                  type="file"
                                  style={{
                                    fontSize: 18,
                                    visibility: "hidden",
                                    position: "relative",
                                    opacity: 1,
                                    margin: 5
                                  }}
                                  className="form-control"
                                  onChange={event => {
                                    this.addBgImage(event, index);
                                  }}
                                />
                              </label>
                              {table.image && (
                                <img
                                  src={table.image}
                                  style={{
                                    margin: 5,
                                    width: 50,
                                    height: 50,
                                    float: "right"
                                  }}
                                />
                              )}
                            </div>

                            <Input
                              type="text"
                              placeholder="Enter Title"
                              defaultValue={table.title}
                              style={{ margin: 5, flex: 1 }}
                              onChange={event => {
                                this.handleTitleChange(event, index);
                              }}
                            />
                          </div>

                          <WYSIWYGEditor
                            editorId="pageContent"
                            onChange={event => {
                              console.warn(event)
                              this.handleEditorChange(event, index);
                            }}
                            updateValues={val => {
                              console.warn(val)

                              this.handleEditorUpdate(val, index);
                            }}
                            // defaultValue={table.title}
                            defaultValue={table.htmlData ? table.htmlData : ""}
                            // defaultValue={
                            //   this.props.obj[0].buttons[index].htmlData
                            // }
                            description="Enter subpage content"
                          />

                        </FormGroup>
                      </Col>
                    </Row>

                    <Button
                      className="btn-round"
                      style={{ float: "right" }}
                      color="danger"
                      type="submit"
                      onClick={() => {
                        this.deleteRow(index);
                      }}
                    >
                      Delete
                          </Button>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
          {/* <Button
            className="btn-round"
            style={{ marginLeft: 20 }}
            color="primary"
            type="submit"
            onClick={() => {
              this.props.changePage(this.state.header, "previous");
            }}
            disabled={this.state.enable ? false : true}
          >
            Previous
          </Button>

          <Button
            className="btn-round"
            style={{ marginRight: 20, float: "right" }}
            color="success"
            type="submit"
            onClick={() => {
              this.props.saveToDB(this.state.header);
            }}
            disabled={this.state.enable ? false : true}
          >
            Create
          </Button> */}

        </div>
      </>
    );
  }
}
