import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  FormGroup,
  Input,
  Button
} from "reactstrap";

export default class Page4 extends React.Component {
  state = {
    update: true,
    url: "",
    title: ""
  };

  componentDidMount = () => {
    this.initialize();
  };

  componentDidUpdate = () => {
    if (this.state.update) {
      this.initialize();

      this.setState({
        update: false
      });
    }
  };

  initialize = () => {
    // console.log("initialize");
    // console.log("Page 4:");
    // console.log("Global Obj: ", this.props);
    // console.log("Local Obj: ", this.state.title, this.state.url);

    // URL
    var temp = this.state.url;
    temp = this.props.obj[0].url;

    // TITLE
    var temp2 = this.state.title;
    temp2 = this.props.obj[0].title;

    this.setState({
      url: temp,
      title: temp2
    });
  };

  // handleURLChange = event => {
  //   let temp = this.state.url;

  //   temp = event.target.value;

  //   this.setState({
  //     url: temp
  //   });
  // };

  render() {
    return (
      <>
        <div className="content">
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Enter YouTube Channel Link:</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <Input
                        type="text"
                        placeholder="Enter Page 4 Title"
                        defaultValue={this.state.title}
                        style={{ margin: 5 }}
                        onChange={event => {
                          this.setState({ title: event.target.value }, () => {
                            this.props.onTitleChange(this.state.title);
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <Input
                        type="url"
                        placeholder="Enter Link"
                        // defaultValue={this.props.obj[0].magazine1.page4}
                        defaultValue={this.state.url}
                        style={{ margin: 5 }}
                        onChange={event => {
                          this.setState({ url: event.target.value }, () => {
                            this.props.onUrlChange(this.state.url);
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          {/* <Button
            className="btn-round"
            style={{ marginLeft: 20 }}
            color="primary"
            type="submit"
            onClick={() => {
              this.props.changePage(this.state.url, "previous");
            }}
          >
            Previous
          </Button>

          <Button
            className="btn-round"
            style={{ float: "right", marginRight: 15 }}
            color="primary"
            type="submit"
            onClick={() => {
              this.props.changePage(this.state.url, "next");
            }}
          >
            Next
          </Button> */}
        </div>
      </>
    );
  }
}
