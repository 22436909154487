import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import firebase from "./../firebase.config";

import NotificationAlert from "react-notification-alert";

export default class Login extends Component {
  state = {
    email: "",
    pass: "",
    toSuperadminDashboard: false,
    toAdminDashboard: false,
    loading: false
  };

  notificationAlert = React.createRef();
  notify(type, message) {
    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>{message}</div>
        </div>
      ),
      type: type,
      icon: "nc-icon nc-touch-id",
      autoDismiss: 30
    };
    alert(message);
    // this.notificationAlert.current.notificationAlert(options);
  }

  handleChange(event, key) {
    this.setState({ [key]: event.target.value });
  }

  validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  loginUser = () => {
    const { email, pass } = this.state;
    if (this.state.loading) {
      return;
    }
    if (!this.validateEmail(email)) {
      this.notify("danger", "Please enter valid email address");
      return;
    }
    if (pass.length < 6) {
      this.notify("danger", "Password should be of minimum 6 characters");
      return;
    }
    this.setState({ loading: true });

    firebase
      .auth()
      .signInWithEmailAndPassword(email, pass)
      .then(res => {
        //check if logged as admin
        firebase
          .database()
          .ref("/superadmin/" + res.user.uid)
          .once("value")
          .then(snap => {
            if (snap.val()) {
              // this.notify("success", "Authentication Successful");
              this.setState({ toSuperadminDashboard: true });
            } else {
              this.setState({ toAdminDashboard: true });

              // firebase.auth().signOut();
              // this.notify(
              //   "danger",
              //   "You don't have enough previleges to login to dashboard"
              // );
              // this.setState({ loading: false });
            }
          })
          .catch(err => {
            console.warn(err.message);
            firebase.auth().signOut();
            this.setState({ loading: false });

            this.notify(
              "danger",
              "There was an error signing in, please try again later."
            );
          });
      })
      .catch(err => {
        this.notify("danger", err.message);
        this.setState({ loading: false });
      });
  };

  render() {
    if (this.state.toSuperadminDashboard === true) {
      return <Redirect to="/superadmin/admins" />;
    }
    else if (this.state.toAdminDashboard === true) {
      return <Redirect to="/admin/dashboard" />;
    }
    return (
      <div
        style={{
          width: "100%",
          paddingLeft: "7%",
          paddingRight: "7%",
          marginTop: 30
        }}
      >
        <NotificationAlert ref={this.notificationAlert} />

        <h3 style={styles.title}>Please Login to continue</h3>
        <input
          style={styles.input}
          placeholder="Email Address"
          onChange={evt => {
            this.handleChange(evt, "email");
          }}
        />

        <input
          style={styles.input}
          placeholder="Password"
          type="password"
          onChange={evt => {
            this.handleChange(evt, "pass");
          }}
        />
        {this.state.loading ? (
          <button style={styles.button} onClick={() => { }}>
            Loading
          </button>
        ) : (
            <button
              style={styles.button}
              onClick={() => {
                this.loginUser();
              }}
            >
              Log in
            </button>
          )}
        <NavLink
          to={"/auth/recover"}
          className="nav-link"
          style={styles.buttonPass}
        >
          Forgot your password?
        </NavLink>
      </div>
    );
  }
}

const styles = {
  title: {
    width: "100%",
    textAlign: "center",
    fontWeight: "600"
  },
  input: {
    width: "100%",
    marginTop: 30,
    borderRadius: 5,
    borderColor: "rgb(190,190,190)",
    borderWidth: 1,
    borderStyle: "solid",
    padding: 20
  },
  button: {
    marginTop: 20,
    width: "100%",
    padding: 12,
    backgroundColor: "rgb(88,141,240)",
    border: 0,
    borderRadius: 5,
    color: "white",
    fontWeight: "600"
  },
  buttonPass: {
    marginTop: 5,
    backgroundColor: "transparent",
    borderWidth: 0,
    width: "100%",
    textAlign: "center",
    cursor: "pointer",
    cursor: "hand",
    fontWeight: "600",
    color: "black"
  },
  buttonSignup: {
    marginTop: 30,
    backgroundColor: "transparent",
    borderWidth: 0,
    width: "100%",
    textAlign: "center",
    color: "black"
  }
};
