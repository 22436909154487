/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import Login from "views/Login";
import Recover from "views/Recover";
import Logout from "views/Logout";
import DashboardAdmin from "views/DashboardAdmin";
import Admins from "views/Admins";
import Users from "views/Users";
import Projects from "views/Projects";
import TermsService from "views/TermsService";
import PrivacyPolicy from "views/PrivacyPolicy";
import Inputs from "views/Inputs";
import Data from "views/Data";
import Page4 from "views/Page4";
import Page5 from "views/Page5";
import Magazines from "views/Magazines";
import Events from "views/Events";
import GroupDetails from "views/GroupDetails";
import Notifications from "views/Notifications";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "nc-icon nc-tile-56",
    component: Login,
    layout: "/auth"
  },

  {
    path: "/recover",
    name: "Recover Password",
    icon: "nc-icon nc-tile-56",
    component: Recover,
    layout: "/auth"
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: DashboardAdmin,
    layout: "/admin"
  },
  {
    path: "/admins",
    name: "Admins",
    icon: "nc-icon nc-spaceship",
    component: Admins,
    layout: "/superadmin"
  },
  {
    path: "/group",
    name: "Group Details",
    icon: "nc-icon nc-spaceship",
    component: GroupDetails,
    layout: "/admin"
  },
  {
    path: "/events",
    name: "Events",
    icon: "nc-icon nc-spaceship",
    component: Events,
    layout: "/admin"
  },
  {
    path: "/newsletter",
    name: "Newsletter",
    icon: "nc-icon nc-spaceship",
    component: Magazines,
    layout: "/admin"
  },
  {
    path: "/notification",
    name: "Push Notification",
    icon: "nc-icon nc-spaceship",
    component: Notifications,
    layout: "/admin"
  },
  // {
  //   path: "/projects",
  //   name: "Page 2",
  //   icon: "nc-icon nc-spaceship",
  //   component: Projects,
  //   layout: "/admin"
  // },
  // {
  //   path: "/data",
  //   name: "Page 3",
  //   icon: "nc-icon nc-spaceship",
  //   component: Data,
  //   layout: "/admin"
  // },
  // {
  //   path: "/page4",
  //   name: "Page 4",
  //   icon: "nc-icon nc-spaceship",
  //   component: Page4,
  //   layout: "/admin"
  // },
  // {
  //   path: "/page5",
  //   name: "Page 5",
  //   icon: "nc-icon nc-spaceship",
  //   component: Page5,
  //   layout: "/admin"
  // },
  // {
  //   path: "/manageAdmins",
  //   name: "Admins",
  //   icon: "nc-icon nc-circle-10",
  //   component: Admins,
  //   layout: "/admin"
  // },
  // {
  //   path: "/manageUsers",
  //   name: "Users",
  //   icon: "nc-icon nc-single-02",
  //   component: Users,
  //   layout: "/admin"
  // },
  // {
  //   path: "/user-page",
  //   name: "User Profile",
  //   icon: "nc-icon nc-spaceship",
  //   component: Icons,
  //   layout: "/admin"
  // },

  // {
  //   path: "/tables",
  //   name: "Table List",
  //   icon: "nc-icon nc-tile-56",
  //   component: Payments,
  //   layout: "/admin"
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-caps-small",
  //   component: Typography,
  //   layout: "/admin"
  // },
  {
    pro: true,
    path: "/upgrade",
    name: "Signout",
    icon: "nc-icon nc-lock-circle-open",
    component: Logout,
    layout: "/admin"
  },
  {
    pro: true,
    path: "/upgrade",
    name: "Signout",
    icon: "nc-icon nc-lock-circle-open",
    component: Logout,
    layout: "/superadmin"
  }
];
export default routes;
