/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Input,
  Button
} from "reactstrap";

import firebase from 'firebase.config'

class DashboardAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      ID: ""
    };
    console.warn("props data", props.data);
  }

  componentDidMount() { }



  addId = async () => {
    if (this.state.ID.length < 2) {
      alert("Please enter a valid ID")
      return
    }

    let idCheck = await firebase.database().ref("groups/" + this.state.ID.toLowerCase()).once('value')
    if (idCheck.val()) {
      alert("The mentioned ID is already in use, please try some other ID")
      return
    }

    let res = window.confirm("Are you sure that you want to set group ID to " + this.state.ID + "? You wont be able to change it later on");

    if (res) {


      firebase.database().ref('/groupsData/').push({
        owner: this.props.uid
      })
        .then(res => {
          firebase.database().ref('/groups/').update({
            [this.state.ID.toLowerCase()]: res.key
          })

          firebase.database().ref('/admin/' + this.props.uid).update({
            group: this.state.ID.toLowerCase(),
            groupPath: res.key
          })

        })


    }
  }

  handleChange = (event, key) => {
    this.setState({
      [key]: event.target.value
    })
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="6" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-globe text-warning" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Newsletters published</p>
                        <CardTitle tag="p">
                          {this.props.stats.newsletters
                            ? this.props.stats.newsletters
                            : 0}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats"></div>
                </CardFooter>
              </Card>
            </Col>
            <Col lg="6" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-single-02 text-success" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">User Subscriptions</p>
                        <CardTitle tag="p">
                          {this.props.stats.subs
                            ? this.props.stats.subs
                            : 0}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats"></div>
                </CardFooter>
              </Card>
            </Col>


            <Col lg="12" md="12" sm="12">

              <Card>

                <CardBody>
                  <Row>
                    <Col md="12">
                      {!this.props.userDetails.group ?
                        (
                          <FormGroup>
                            <label>Group Unique ID: </label>
                            <div
                              style={{
                                flexDirection: "row",
                                display: "flex",

                              }}
                            >
                              <Input
                                type="text"
                                placeholder="Enter ID"
                                style={{ margin: 5, flex: 1 }}
                                onChange={event => {
                                  this.handleChange(event, "ID");
                                }}
                              />

                              <Button
                                className="btn-round"
                                style={{ marginBottom: 20, marginLeft: 20, borderRadius: 2, height: '100%' }}
                                color="primary"
                                type="submit"
                                onClick={() => {
                                  this.addId();
                                }}
                              >
                                Update Group ID
                          </Button>

                            </div>



                          </FormGroup>
                        )
                        :
                        (
                          <p style={{ textAlign: 'center', paddingTop: 5 }}>Dear {this.props.userDetails.name}, your group id is <b>{this.props.userDetails.group}</b></p>
                        )}

                    </Col>
                  </Row>

                </CardBody>
              </Card>

            </Col>
            {/* <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-vector text-danger" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Sales</p>
                        <CardTitle tag="p">$ {this.props.revenue}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                  </div>
                </CardFooter>
              </Card>
            </Col> */}
            {/* <Col lg="3" md="6" sm="6">
              <Card className="card-stats">
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-favourite-28 text-primary" />
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Total Bookings</p>
                        <CardTitle tag="p">{this.props.bookings}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                  </div>
                </CardFooter>
              </Card>
            </Col> */}
          </Row>
          {/* <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Bookings</CardTitle>
                  <p className="card-category">24 Hours performance</p>
                </CardHeader>
                <CardBody>
                  <Line
                    data={dashboard24HoursPerformanceChart.data}
                    options={dashboard24HoursPerformanceChart.options}
                    width={400}
                    height={100}
                  />
                </CardBody>
                <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-history" /> Updated in Realtime
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row> */}
        </div>
      </>
    );
  }
}

export default DashboardAdmin;
