import React, { Component } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import ToolbarOptions from "./ToolbarOptions";

export default class WYSIWYGEditor extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    updatedOnce: false
  };

  constructor(props) {
    super(props);
    const html = this.props.defaultValue ? this.props.defaultValue : "";
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
        updatedOnce: false

      };
      this.props.updateValues(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      );
    }

    console.log("WYSIWYG:");
    console.log(this.props.editorId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.defaultValue !== nextProps.defaultValue && this.state.updatedOnce === false) {
      const html = nextProps.defaultValue ? nextProps.defaultValue : "";
      if (nextProps.defaultValue.length > 1) {
        this.setState({
          updatedOnce: true
        })
      }
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );


        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(html)
          )
        )
        const editorState = EditorState.createWithContent(contentState);
        this.setState({
          editorState,
        })
        this.props.updateValues(
          draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
      }
    }
  }


  onEditorStateChange = editorState => {
    this.setState(
      {
        editorState
      },
      () => {
        console.log(this.state);
      }
    );
  };

  render() {
    const { editorState } = this.state;

    return (
      <div>
        <Editor
          editorState={editorState}
          toolbar={ToolbarOptions}
          wrapperClassName={styles.editor}
          editorClassName={styles.editor}
          onEditorStateChange={this.onEditorStateChange}
          wrapperStyle={{
            border: "solid rgba(0,0,0,0.3) 1px",
            borderRadius: 4
          }}
          toolbarStyle={{
            borderRadius: 4
          }}
          editorStyle={{
            paddingLeft: 10,
            paddingRight: 10,
            minHeight: this.props.height ? this.props.height : 100,
            maxHeight: 700,
            marginBottom: 5,
            cursor: "text"
          }}
          wrapperStyle={{
            border: "solid rgba(0,0,0,0.1) 1px",
            borderRadius: 4
          }}
          placeholder={this.props.description}
          onChange={event => {
            this.props.updateValues(
              draftToHtml(convertToRaw(editorState.getCurrentContent()))
            );
          }}
        />
      </div>
    );
  }
}
const styles = {
  editor: {
    ":&hover": {
      backgroundColor: "orange"
    }
  }
};
