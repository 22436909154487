/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
// reactstrap components
import { Card, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import WYSIWYGEditor from "components/WYSIWYGEditor";
import firebase from 'firebase.config'

class TermsService extends React.Component {
    state = {
        content: ''
    }

    update = () => {
        firebase.database().ref('/').update({
            termsConditions: this.state.content
        })
            .then(res => {
                alert("Terms & Conditions updated successfully")
            })
            .catch(err => {
                alert("There was an error updating Terms & Conditions, please try again later")
            })
    }
    render() {
        console.warn(this.props.termsConditions)
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardBody>
                                    <WYSIWYGEditor
                                        editorId="pageContent"

                                        onChange={event => {

                                            this.setState({
                                                content: event.target.value
                                            })
                                        }}
                                        height={400}
                                        updateValues={(val) => {
                                            this.setState({
                                                content: val
                                            })
                                        }}
                                        defaultValue={this.props.termsConditions}


                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <div className="update ml-auto mr-auto">
                            <Button
                                className="btn-round"
                                color="primary"
                                type="submit"
                                onClick={() => { this.update() }}
                            >
                                Update Terms Of Service
                                    </Button>
                        </div>
                    </Row>
                </div>
            </>
        );
    }
}

export default TermsService;
