/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import { Route, Switch, Redirect } from "react-router-dom";

import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import firebase from "./../firebase.config";

import routes from "routes.js";

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      toAuthScreen: false,
      adminAccounts: [],
      uid: "",


    };
    this.mainPanel = React.createRef();
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      console.warn(user);

      if (user) {
        this.getUserDetails();
      } else {
        this.setState({
          toAuthScreen: true
        });
      }
    });
  }

  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };

  getUserDetails = () => {
    console.warn("Get user details called");
    let user = firebase.auth().currentUser;
    this.setState({
      uid: user.uid
    });
    firebase
      .database()
      .ref("/superadmin/" + user.uid)
      .on("value", async snap => {
        if (!snap.val()) {
          this.setState({ toAuthScreen: true });
        } else {
          this.getAdmins()
        }
      });
  };

  getAdmins = () => {
    firebase.database().ref('/admin/').on('value', snap => {
      let data = []
      if (snap.val()) {
        let keys = Object.keys(snap.val())
        for (let i = 0; i < keys.length; i++) {
          data.push({ ...snap.val()[keys[i]], key: keys[i] })
        }
        this.setState({
          adminAccounts: data
        })
      }
    })

  }

  render() {
    if (this.state.toAuthScreen === true) {
      return <Redirect to="/auth" />;
    }

    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <DemoNavbar {...this.props} />
          <Switch>
            {routes.map((prop, key) => {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  component={() => (
                    <prop.component
                      uid={this.state.uid}
                      admins={this.state.adminAccounts}

                    />
                  )}
                  key={key}
                />
              );
            })}
          </Switch>
          <Footer fluid />
        </div>

      </div>
    );
  }
}

export default Dashboard;
