/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Row, Col } from "reactstrap";
import routes from "routes.js";

let bg = require("./../assets/img/news_bg02.jpg");

var ps;

class Auth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info"
    };
  }

  handleActiveClick = color => {
    this.setState({ activeColor: color });
  };
  handleBgClick = color => {
    this.setState({ backgroundColor: color });
  };
  render() {
    return (
      <div className="wrapper" style={{ overflow: "hidden" }}>
        <Row
          style={{
            height: "100%",
            backgroundColor: "#F7F7F7",
            backgroundImage: "url(" + bg + ")",
            backgroundSize: "cover",
            overflow: "hidden"
          }}
        >
          <Col xs={0} sm={4} md={7} style={{ paddingRight: 0 }}></Col>
          <Col
            xs={12}
            sm={8}
            md={5}
            style={{
              backgroundColor: "white",
              overflowY: "auto",
              maxHeight: "100%"
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 70
              }}
            >
              <h1 style={{ fontWeight: "bolder" }}>Smart Newsletter</h1>
            </div>

            <Switch style={{ width: "100%" }}>
              {routes.map((prop, key) => {
                return (
                  <Route
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
              <Redirect to="/auth/login" />
            </Switch>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Auth;
