/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  Table
} from "reactstrap";
import firebase from "firebase.config";
import axios from 'axios'
class Admins extends React.Component {

  state = {
    name: "",
    email: "",
    pass: "",
    groupId: ""

  }


  // componentDidMount() {
  //   firebase.auth().onAuthStateChanged((user) => {
  //     if (user) {

  //       firebase.database().ref('/superadmin/' + user.uid).once('value')
  //         .then(snap => {
  //           console.warn(snap.val())
  //           if (snap.val()) {

  //           }
  //           else {

  //           }
  //         })
  //       // ...
  //     } else {
  //       // User is signed out.
  //       // ...
  //     }
  //   });
  // }

  disableAccount = acc => {
    console.warn(acc);
    console.warn(acc.key);

    firebase
      .database()
      .ref("/employees/" + acc.key)
      .update({
        disabled: !acc.disabled
      });
  };

  deleteAccount = acc => {
    console.warn(acc);
    let res = window.confirm("Are you sure that you want to delete");
    if (res) {
      console.warn("Call api");
      firebase
        .database()
        .ref("/employees/" + acc.key)
        .update({
          deleted: true
        });
    }
  };


  createAccount = () => {
    if (this.state.name.length < 2 || this.state.email.length < 5 || this.state.pass.length < 6 || this.state.groupId.length < 2) {
      alert("Please enter valid data")
    }

    fetch("https://us-central1-newsletterapp-b6070.cloudfunctions.net/createAdmin", {
      method: "POST",
      // headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        pass: this.state.pass,
        groupId: this.state.groupId
      })
    })
      .then(response => response.json())
      .then(resp => {
        this.setState({
          name: "",
          email: "",
          pass: "",
          groupId: ""
        })
      })
      .catch(err => {
        alert("There was an error creating admin,please check that there is not group with the same ID and try again later")
        console.warn(err)

      })

  }

  render() {
    return (
      <>
        <div className="content">
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Create New Admin</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>New Admin Details: </label>
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <Input
                          placeholder="Enter Name"
                          style={{ margin: 5 }}
                          value={this.state.name}
                          onChange={(event) => {
                            console.warn(event.target.value)
                            this.setState({
                              name: event.target.value
                            })
                          }}
                          required
                        />


                        <Input
                          placeholder="Enter Unique Group ID"
                          style={{ margin: 5 }}
                          required
                          value={this.state.groupId}

                          onChange={(event) => {
                            console.warn(event.target.value)
                            this.setState({
                              groupId: event.target.value
                            })
                          }}
                        />

                      </div>


                      <div style={{ flexDirection: "row", display: "flex" }}>

                        <Input
                          placeholder="Enter Email"
                          style={{ margin: 5 }}
                          required
                          value={this.state.email}

                          onChange={(event) => {
                            console.warn(event.target.value)
                            this.setState({
                              email: event.target.value
                            })
                          }}
                        />
                        <Input
                          placeholder="Enter Password"
                          style={{ margin: 5 }}
                          required
                          value={this.state.pass}

                          onChange={(event) => {
                            console.warn(event.target.value)
                            this.setState({
                              pass: event.target.value
                            })
                          }}
                        />

                      </div>

                      <Button
                        onClick={() => {
                          this.createAccount()
                        }}
                        className="btn-round"
                        style={{ float: "right" }}
                        color="primary"
                        type="submit"
                      >
                        Create
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Row>
              <div className="update ml-auto mr-auto"></div>
            </Row>
          </Col>

          <Row>
            <Col md="12">
              <Card className="card-plain">
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Group</th>
                        {/* <th className="text-right"></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.admins.map((acc, index) => {
                        if (acc.deleted !== true)
                          return (
                            <tr>
                              <td>{acc.name}</td>
                              <td>{acc.email}</td>
                              <td>{acc.group}</td>

                              {/* <td className="text-right">
                                <div className="update ml-auto mr-auto">
                                  <Button
                                    className="btn-round"
                                    color={
                                      acc.disabled === true
                                        ? "primary"
                                        : "success"
                                    }
                                    type="submit"
                                    onClick={() => {
                                      this.disableAccount(acc);
                                    }}
                                  >
                                    {acc.disabled === true
                                      ? "Enable Account"
                                      : "Disable Account"}
                                  </Button>

                                  <Button
                                    className="btn-round"
                                    style={{
                                      paddingLeft: 12,
                                      paddingRight: 12,
                                      marginLeft: 4
                                    }}
                                    color="primary"
                                    type="submit"
                                    onClick={() => {
                                      this.deleteAccount(acc);
                                    }}
                                  >
                                    X
                                  </Button>
                                </div>
                              </td> */}
                            </tr>
                          );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Admins;
