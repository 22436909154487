/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
// reactstrap components
import { Card, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import WYSIWYGEditor from "components/WYSIWYGEditor";
import firebase from 'firebase.config'
class PrivacyPolicy extends React.Component {
    state = {
        content: ''
    }

    componentDidMount() {
        firebase.database().ref('/privacyPolicy').once('value')
            .then(snap => {
                if (snap.val()) {
                    console.warn(snap.val())
                    this.setState({
                        content: snap.val()
                    }, () => {
                        this.contentHolder.forceUpdate();

                    })
                }
            })
    }


    update = () => {
        firebase.database().ref('/').update({
            privacyPolicy: this.state.content
        })
            .then(res => {
                alert("Privacy Policy updated successfully")
            })
            .catch(err => {
                alert("There was an error updating privacy policy,please try again later")
            })
    }
    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>

                                <CardBody>
                                    <WYSIWYGEditor
                                        editorId="pageContent"

                                        onChange={event => {

                                            this.setState({
                                                content: event.target.value
                                            })
                                        }}
                                        height={400}
                                        updateValues={(val) => {
                                            this.setState({
                                                content: val
                                            })
                                        }}
                                        defaultValue={this.props.privacyPolicy}

                                        ref={ref => this.contentHolder = ref}

                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <div className="update ml-auto mr-auto">
                            <Button
                                className="btn-round"
                                color="primary"
                                type="submit"
                                onClick={() => { this.update() }}
                            >
                                Update Privacy Policy
                                    </Button>
                        </div>
                    </Row>
                </div>
            </>
        );
    }
}

export default PrivacyPolicy;
