import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  FormGroup,
  Input,
  Button
} from "reactstrap";
import WYSIWYGEditor from "components/WYSIWYGEditor";

export default class Page3 extends React.Component {
  state = {
    headers: [
      {
        header: "",
        text: ""
        // text2: ""
      }
    ]
  };

  componentDidMount = () => {
    this.initialize();
  };

  initialize = () => {
    console.log("Page 3:");
    console.log("Global Obj: ", this.props.obj);

    var temp = this.state.headers;
    temp[0].header = this.props.obj[0].magazine1.page3.header;
    temp[0].text = this.props.obj[0].magazine1.page3.text;

    this.setState({
      headers: temp
    });

    console.log("Local Obj: ", this.state.magazine);
    console.log("Tepm Obj: ", temp);
  };

  handleChange = (event, key) => {
    let temp = this.state.headers;

    temp[0][key] = event.target.value;

    this.setState({ headers: temp });
  };

  handleEditorChange = event => {
    let temp = this.state.headers;

    temp[0].text = event.target.value;

    this.setState({
      headers: temp
    });
  };

  handleEditorUpdate = val => {
    let temp = this.state.headers;

    temp[0].text = val;

    this.setState({
      headers: temp
    });
  };

  checkIfEmpty = page => {
    var temp = this.state.headers[0];

    if (!temp.header || !temp.text) {
      alert("All fields are required");
      return;
    }
    this.props.changePage(this.state.headers, page);
  };

  render() {
    return (
      <>
        <div className="content">
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">New Header</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>New Header Details: </label>
                      {console.log(
                        "Default Value::",
                        this.props.obj[0].magazine1.page3.header
                      )}
                      <Input
                        type="text"
                        placeholder="Enter Heading"
                        defaultValue={this.props.obj[0].magazine1.page3.header}
                        style={{ margin: 5 }}
                        onChange={event => {
                          this.handleChange(event, "header");
                        }}
                      />
                      <WYSIWYGEditor
                        editorId="pageContent"
                        onChange={event => {
                          this.handleEditorChange(event);
                        }}
                        updateValues={val => {
                          this.handleEditorUpdate(val);
                        }}
                        defaultValue={this.props.obj[0].magazine1.page3.text}
                        description="Enter text"
                      />

                      {/* <Input
                        type="textarea"
                        placeholder="Enter Text"
                        defaultValue={this.props.obj[0].magazine1.page3.text}
                        style={{ margin: 5 }}
                        onChange={event => {
                          this.handleChange(event, "text");
                        }}
                      /> */}
                      {/* <Input
                        type="textarea"
                        placeholder="Enter Text"
                        defaultValue={this.props.obj[0].magazine1.page3.text2}
                        style={{ margin: 5 }}
                        onChange={event => {
                          this.handleChange(event, "text2");
                        }}
                      /> */}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Button
            className="btn-round"
            style={{ marginLeft: 20 }}
            color="primary"
            type="submit"
            onClick={() => {
              this.props.changePage(this.state.headers, "previous");
            }}
          >
            Previous
          </Button>
          <Button
            className="btn-round"
            style={{ float: "right", marginRight: 15 }}
            color="primary"
            type="submit"
            onClick={() => {
              this.props.saveToDB(this.state.headers);
            }}
          >
            {this.props.edit ? "Save" : "Create"}
          </Button>
        </div>
      </>
    );
  }
}
