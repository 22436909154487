/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col, FormGroup, Input, Button, Table } from "reactstrap";

class Users extends React.Component {


    disableAccount = (acc) => {
        console.warn(acc)
        console.warn(acc.key)

        let currentStatus = acc.disabled ? acc.disabled : false
        fetch("https://us-central1-cryofind-ecb23.cloudfunctions.net/disableUser", {
            method: "POST",
            // headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                uid: acc.key,
                disable: !currentStatus
            })
        })
            .then(response => response.json())
            .then(resp => {

                console.warn(resp)
            })
            .catch(err => {
                alert("There was an error changing account status,please try again later.")
                console.warn(err)

            })
    }

    render() {
        return (
            <>
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card className="card-plain">
                                <CardBody>
                                    <Table responsive>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>Details</th>
                                                <th>Bookings</th>
                                                <th>Earnings from this user</th>
                                                <th className="text-right"></th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.users.map((acc, index) => {
                                                return (
                                                    <tr>
                                                        <td>{acc.fName} {acc.lName}<br />{acc.email}<br />{acc.num}</td>
                                                        <td>{acc.bookings ? acc.bookings : 0}</td>
                                                        <td>${acc.revenue ? acc.revenue : 0}</td>
                                                        <td className="text-right">
                                                            <div className="update ml-auto mr-auto">

                                                                <Button
                                                                    className="btn-round"
                                                                    color="primary"
                                                                    type="submit"
                                                                    onClick={() => { this.disableAccount(acc) }}
                                                                >

                                                                    {acc.disabled === true ? "Enable" : "Disable"}
                                                                </Button>


                                                            </div>


                                                        </td>
                                                    </tr>
                                                )
                                            })}

                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </div>
            </>
        );
    }
}

export default Users;
