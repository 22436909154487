/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Button,
  CustomInput
} from "reactstrap";

import firebase from "firebase.config";
export default class Inputs extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.myRef = React.createRef();
  // }

  state = {
    headers: [
      {
        title: "",
        rows: [
          {
            column1: "",
            column2: ""
          }
        ]
      }
    ],

    magazine: [
      {
        date: "",
        noOfWeeks: 0,
        image: "",
        text: "",

        headers: [
          {
            title: "",

            rows: [
              {
                column1: "",
                column2: ""
              }
            ]
          }
        ]
      }
    ]
  };

  //   firebase
  //     .database()
  //     .ref("/inputs/carPlaque/")
  //     .push({
  //       [this.state.title]: true
  //     });
  // };

  // firebase
  //     .database()
  //     .ref("/inputs/hourSegment/")
  //     .push({
  //       [this.state.htitle]: true
  //     });
  // };

  // scrollToMyRef = () => {
  //   window.scrollTo(0, this.myRef.current.offsetTop);
  // };

  addHeader = () => {
    var temp = this.state.headers;

    temp.push({
      title: "",
      rows: [{ column1: "", column2: "" }]
    });

    this.setState({
      headers: temp
    });

    // this["ref" + this.state.headers.length - 1] = React.createRef();
    // this.scrollToMyRef();
    // window.scrollTo(0, this.myRef.current.offsetTop);
    // window.scrollTo({ top: 0, behavior: "smooth" });
  };

  addRow = index => {
    let temp = this.state.headers;
    temp[index].rows.push({
      column1: "",
      column2: ""
    });

    this.setState({
      headers: temp
    });
  };

  removeRow = (index, indexInner) => {
    let temp = this.state.headers;

    temp[index].rows.splice(indexInner, 1);

    this.setState({ headers: temp });
    console.log(this.state.headers);
  };

  handleHeadingChange = (event, index) => {
    let temp = this.state.headers;

    temp[index].title = event.target.value;

    this.setState({ headers: temp });
  };

  handleColumnChange = (event, index, indexInner, col1) => {
    let temp = this.state.headers;

    col1
      ? (temp[index].rows[indexInner].column1 = event.target.value)
      : (temp[index].rows[indexInner].column2 = event.target.value);

    this.setState({ headers: temp });
  };

  render() {
    return (
      <>
        <div className="content">
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Create New Magazine</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <FormGroup>
                      <label>New Magazine Details: </label>
                      <div style={{ flexDirection: "row", display: "flex" }}>
                        <Input
                          placeholder="Enter Date"
                          style={{ margin: 5 }}
                          required
                        />
                        <Input
                          placeholder="No of Weeks"
                          style={{ margin: 5 }}
                          required
                        />
                      </div>
                      <Input
                        type="file"
                        style={{ position: "relative", opacity: 1, margin: 5 }}
                        className="form-control"
                      />
                      <Input
                        type="textarea"
                        placeholder="Enter Text Here"
                        style={{ margin: 5 }}
                      />
                      <Button
                        className="btn-round"
                        style={{ float: "right" }}
                        color="primary"
                        type="submit"
                        onClick={() => {
                          this.addHeader();
                        }}
                      >
                        Add Header
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          {this.state.headers.map((table, index) => {
            return (
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h5">New Header</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>New Header Details: </label>
                          <Input
                            type="text"
                            placeholder="Enter Heading"
                            defaultValue={table.title}
                            style={{ margin: 5 }}
                            onChange={event => {
                              this.handleHeadingChange(event, index);
                            }}
                          />
                          {table.rows.map((row, indexInner) => {
                            return (
                              <div
                                // ref={this["ref" + index]}
                                style={{
                                  flexDirection: "row",
                                  display: "flex"
                                }}
                              >
                                <Input
                                  type="textarea"
                                  placeholder="Enter Col1"
                                  value={row.column1}
                                  style={{ margin: 5 }}
                                  required
                                  onChange={event => {
                                    this.handleColumnChange(
                                      event,
                                      index,
                                      indexInner,
                                      true
                                    );
                                  }}
                                />
                                <Input
                                  type="textarea"
                                  placeholder="Enter Col2"
                                  value={row.column2}
                                  style={{ margin: 5 }}
                                  required
                                  onChange={event => {
                                    this.handleColumnChange(
                                      event,
                                      index,
                                      indexInner,
                                      false
                                    );
                                  }}
                                />
                                <Button
                                  className="btn-round"
                                  style={{
                                    marginTop: 25,
                                    marginBottom: 25
                                  }}
                                  color="danger"
                                  type="submit"
                                  onClick={() => {
                                    this.removeRow(index, indexInner);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            );
                          })}
                          <Button
                            className="btn-round"
                            style={{
                              paddingTop: 7,
                              paddingBottom: 0,
                              paddingLeft: 15,
                              paddingRight: 15
                            }}
                            color="primary"
                            type="submit"
                            onClick={() => {
                              this.addRow(index);
                            }}
                          >
                            <p
                              style={{
                                fontSize: 30,
                                marginTop: 9,
                                fontWeight: "bold"
                              }}
                            >
                              +
                            </p>
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </div>
      </>
    );
  }
}
