/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Button,
  CustomInput,
  Table
} from "reactstrap";

import firebase from "firebase.config";
class Projects extends React.Component {
  state = {
    headers: [
      {
        title: "",
        text: ""
      }
    ]
  };

  //   firebase
  //     .database()
  //     .ref("/projects/" + this.state.title)
  //     .set({
  //       tasks: pages
  //     });
  // };

  // firebase
  //   .database()
  //   .ref("/projects/" + this.state.oldKey)
  //   .remove();
  // firebase
  //   .database()
  //   .ref("/projects/" + this.state.title)
  //   .set({
  //     tasks: pages
  //   })
  //   .then(snap => {
  //     console.warn("Updated");
  //     this.setState({
  //       type: "action",
  //       title: "",
  //       due: new Date(),
  //       pages: [],
  //       editMode: false,
  //       selectedKey: null
  //     });
  //   })
  //   .catch(err => {
  //     console.warn("Update failed");

  //     alert(
  //       "Se produjo un error al actualizar el proyecto. Vuelve a intentarlo más tarde."
  //     );
  //   });
  // };

  // compare = (a, b) => {
  //   if (a.key > b.key) {
  //     return !this.state.ascending ? -1 : 1;
  //   }
  //   if (a.key < b.key) {
  //     return !this.state.ascending ? 1 : -1;
  //   }
  //   return 0;
  // };

  addHeader = () => {
    var temp = this.state.headers;

    temp.push({
      title: "",
      text: ""
    });

    this.setState({
      headers: temp
    });

    // this["ref" + this.state.headers.length - 1] = React.createRef();
    // this.scrollToMyRef();
    // window.scrollTo(0, this.myRef.current.offsetTop);
    // window.scrollTo({ top: 0, behavior: "smooth" });
  };

  removeRow = (index, indexInner) => {
    let temp = this.state.headers;

    temp[index].rows.splice(indexInner, 1);

    this.setState({ headers: temp });
    console.log(this.state.headers);
  };

  handleChange = (event, index, header) => {
    let temp = this.state.headers;

    header
      ? (temp[index].title = event.target.value)
      : (temp[index].text = event.target.value);

    this.setState({ headers: temp });
  };

  render() {
    return (
      <>
        <div className="content">
          <Button
            className="btn-round"
            style={{ marginBottom: 20, marginLeft: 20 }}
            color="primary"
            type="submit"
            onClick={() => {
              this.addHeader();
            }}
          >
            Add Header
          </Button>

          {this.state.headers.map((table, index) => {
            return (
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h5">New Header</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>New Header Details: </label>
                          <Input
                            type="text"
                            placeholder="Enter Heading"
                            defaultValue={table.title}
                            style={{ margin: 5 }}
                            onChange={event => {
                              this.handleChange(event, index, true);
                            }}
                          />
                          <Input
                            type="textarea"
                            placeholder="Enter Text"
                            defaultValue={table.text}
                            style={{ margin: 5 }}
                            onChange={event => {
                              this.handleChange(event, index, false);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </div>
      </>
    );
  }
}

export default Projects;
