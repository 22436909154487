import React from "react";
// react plugin used to create google maps
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  FormGroup,
  Input,
  Button
} from "reactstrap";
import firebase from "firebase.config";

export default class Page2 extends React.Component {
  state = {
    isEmpty: true,

    headers: [
      {
        header: "",
        text: ""
        // text2: ""
      }
    ]
  };

  componentDidMount = () => {
    this.initialize();
  };

  initialize = () => {
    console.log("Page 2:");
    console.log("Global Obj: ", this.props.obj);

    var temp = this.state.headers;
    temp = this.props.obj[0].magazine1.page2;

    this.setState({
      headers: temp
    });
    console.log("Local Obj: ", this.state.magazine);
  };

  addHeader = () => {
    var temp = this.state.headers;

    temp.push({
      header: "",
      text: ""
      // text2: ""
    });

    this.setState({
      headers: temp
    });
  };

  deleteHeader = index => {
    let temp = this.state.headers;

    // console.log("Data to be altered: ", temp, "Index to be deleted", index);
    temp.splice(index, 1);

    this.setState({
      headers: temp
    });
    // console.log("Headers: ", this.state.headers);
  };

  handleChange = (event, index, key) => {
    console.log("Page 2 :", this.state.headers);
    let temp = this.state.headers;

    temp[index][key] = event.target.value;

    this.setState({ headers: temp });
  };

  render() {
    return (
      <>
        <div className="content">
          {this.props.obj[0].magazine1.page2.map((table, index) => {
            console.log("Map function data:", this.state.headers);
            console.log("Map function table:", table);
            // {this.state.headers.map((table, index) => {
            return (
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h5">New Header</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>New Header Details: </label>
                          <Input
                            type="text"
                            placeholder="Enter Heading"
                            defaultValue={table.header}
                            style={{ margin: 5 }}
                            onChange={event => {
                              this.handleChange(event, index, "header");
                            }}
                          />
                          <Input
                            type="textarea"
                            placeholder="Enter Text"
                            defaultValue={table.text}
                            style={{ margin: 5 }}
                            onChange={event => {
                              this.handleChange(event, index, "text");
                            }}
                          />
                          <Button
                            className="btn-round"
                            style={{ float: "right" }}
                            color="danger"
                            type="submit"
                            onClick={() => {
                              this.deleteHeader(index);
                            }}
                          >
                            Delete
                          </Button>
                          {/* <Input
                            type="textarea"
                            placeholder="Enter Text"
                            defaultValue={table.text2}
                            style={{ margin: 5 }}
                            onChange={event => {
                              this.handleChange(event, index, "text2");
                            }}
                          /> */}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              className="btn-round"
              style={{ marginBottom: 20, marginLeft: 20 }}
              color="primary"
              type="submit"
              onClick={() => {
                this.addHeader();
              }}
            >
              Add Header
            </Button>
          </div>
          <Button
            className="btn-round"
            style={{ marginLeft: 20 }}
            color="primary"
            type="submit"
            onClick={() => {
              this.props.changePage(this.state.headers, "previous");
            }}
          >
            Previous
          </Button>

          <Button
            className="btn-round"
            style={{ float: "right", marginRight: 15 }}
            color="primary"
            type="submit"
            onClick={() => {
              this.props.changePage(this.state.headers, "next");
            }}
          >
            Next
          </Button>
        </div>
      </>
    );
  }
}
